<template>
  <div class="health">
    <h3>Läkarintyg</h3>
    <b-row>
      <b-col cols="4">
        <b-form>
          <b-form-group label="Läkare">
            <b-form-select
              v-model="doctor"
              :class="{ 'is-invalid': $v.doctor.$error }"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >Välj läkare</b-form-select-option
                >
              </template>
              <template v-if="doctors.length > 0">
                <b-form-select-option
                  :value="doctor"
                  v-for="(doctor, doctorKey) in doctors"
                  :key="doctorKey"
                >
                  {{ doctor.city }}: {{ doctor.doctor }}, {{ doctor.company }}
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group label="Sista giltighetsdag">
            <b-form-datepicker
              start-weekday="1"
              v-model="endDate"
              placeholder="T o m"
              style="float: left"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              :class="{ 'is-invalid': $v.endDate.$error }"
            ></b-form-datepicker>
          </b-form-group>
          <h4>Dispens</h4>
          <b-form-group label="Dispens finns">
            <b-form-checkbox v-model="exemption"> </b-form-checkbox>
          </b-form-group>
          <b-form-group v-if="exemption" label="Giltighet">
            <b-form-datepicker
              start-weekday="1"
              v-model="exemptionEndDate"
              placeholder="T o m"
              style="float: left"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              :class="{ 'is-invalid': $v.exemptionEndDate.$error }"
            ></b-form-datepicker>
          </b-form-group>
          <b-form-group v-if="exemption" label="Tilläggsuppgifter">
            <b-form-input
              v-model="exemptionNotes"
              placeholder="Anmärkning"
              :class="{ 'is-invalid': $v.exemptionNotes.$error }"
            ></b-form-input>
          </b-form-group>
          <b-button variant="success" @click="savePersonHealth()"
            >Spara</b-button
          >
        </b-form>
      </b-col>
      <b-col cols="8">
        <legend>&nbsp;</legend>
        <b-table
          v-if="personHealth.length > 0"
          striped
          hover
          :items="personHealth"
          :fields="healthFields"
        >
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapState } from "vuex";
export default {
  name: "health",
  props: ["personID"],
  data() {
    return {
      doctor: null,
      doctors: [],
      endDate: null,
      exemption: false,
      exemptionNotes: null,
      exemptionEndDate: null,
      submitStatus: null,
      personHealth: [],
      healthFields: [
        { key: "doctor", label: "Läkare" },
        { key: "city", label: "Ort" },
        { key: "company", label: "Företag" },
        { key: "exemptNotes", label: "Tilläggsuppgifter" },
        { key: "expire", label: "Sista giltighetsdag" },
        { key: "validUntil", label: "Giltighet" },
      ]
    };
  },

  validations: {
    endDate: { required },
    doctor: { required },
    exemptionEndDate: {
      required: requiredIf(function () {
        return this.exemption == true;
      }),
    },
    exemptionNotes: {
      required: requiredIf(function () {
        return this.exemption == true;
      }),
    },
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
  },

  mounted() {
    this.fetchDoctors();
    this.fetchPersonHealth();
  },

  methods: {
    fetchDoctors() {
      this.$http
        .get(this.baseUrl + "api/get.php?type=doctors&key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.doctors = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchPersonHealth() {
      this.$http
        .get(
          this.baseUrl +
            "api/get.php?type=health&personID=" +
            this.personID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.personHealth = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    savePersonHealth() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";

        var formData = new FormData();
        formData.append("personID", this.personID);
        formData.append("doctorID", this.doctor.doctorID);
        formData.append("expire", this.endDate);

        if (this.exemptionEndDate != null) {
          formData.append("exemptExpire", this.exemptionEndDate);
        }

        if (this.exemptionNotes != null) {
          formData.append("exemptNotes", this.exemptionNotes);
        }

        this.$http
          .post(
            this.baseUrl + "api/edit.php?type=addHealth&key=" + this.apiKey,
            formData
          )
          .then((response) => {
            if (response.data.status == "ok") {
              this.fetchPersonHealth();
              this.doctor = null;
              this.endDate = null;
              this.exemption = false;
              this.exemptionEndDate = null;
              this.exemptionNotes = null;
              this.$v.$reset();
              this.$notify({
                group: "alerts",
                title: "Meddelande",
                text: "Läkarintyg tillagt",
                type: "success",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss">
</style>