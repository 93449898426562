var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"health"},[_c('h3',[_vm._v("Läkarintyg")]),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form',[_c('b-form-group',{attrs:{"label":"Läkare"}},[_c('b-form-select',{class:{ 'is-invalid': _vm.$v.doctor.$error },scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Välj läkare")])]},proxy:true}]),model:{value:(_vm.doctor),callback:function ($$v) {_vm.doctor=$$v},expression:"doctor"}},[(_vm.doctors.length > 0)?_vm._l((_vm.doctors),function(doctor,doctorKey){return _c('b-form-select-option',{key:doctorKey,attrs:{"value":doctor}},[_vm._v(" "+_vm._s(doctor.city)+": "+_vm._s(doctor.doctor)+", "+_vm._s(doctor.company)+" ")])}):_vm._e()],2)],1),_c('b-form-group',{attrs:{"label":"Sista giltighetsdag"}},[_c('b-form-datepicker',{class:{ 'is-invalid': _vm.$v.endDate.$error },staticStyle:{"float":"left"},attrs:{"start-weekday":"1","placeholder":"T o m","date-format-options":{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c('h4',[_vm._v("Dispens")]),_c('b-form-group',{attrs:{"label":"Dispens finns"}},[_c('b-form-checkbox',{model:{value:(_vm.exemption),callback:function ($$v) {_vm.exemption=$$v},expression:"exemption"}})],1),(_vm.exemption)?_c('b-form-group',{attrs:{"label":"Giltighet"}},[_c('b-form-datepicker',{class:{ 'is-invalid': _vm.$v.exemptionEndDate.$error },staticStyle:{"float":"left"},attrs:{"start-weekday":"1","placeholder":"T o m","date-format-options":{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }},model:{value:(_vm.exemptionEndDate),callback:function ($$v) {_vm.exemptionEndDate=$$v},expression:"exemptionEndDate"}})],1):_vm._e(),(_vm.exemption)?_c('b-form-group',{attrs:{"label":"Tilläggsuppgifter"}},[_c('b-form-input',{class:{ 'is-invalid': _vm.$v.exemptionNotes.$error },attrs:{"placeholder":"Anmärkning"},model:{value:(_vm.exemptionNotes),callback:function ($$v) {_vm.exemptionNotes=$$v},expression:"exemptionNotes"}})],1):_vm._e(),_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.savePersonHealth()}}},[_vm._v("Spara")])],1)],1),_c('b-col',{attrs:{"cols":"8"}},[_c('legend'),(_vm.personHealth.length > 0)?_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.personHealth,"fields":_vm.healthFields}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }