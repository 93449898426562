<template>
  <div class="vehicles">
    <b-form>
      <b-form-group label="Fordonstyp" class="form-inline">
        <b-form-select
          class="mr-2"
          v-model="vehicle"
          :class="{ 'is-invalid': $v.vehicle.$error }"
        >
          <b-form-select-option :value="null"
            >Välj fordonstyp</b-form-select-option
          >
          <template v-if="vehicles.length > 0">
            <b-form-select-option
              :value="vehicle"
              v-for="(vehicle, index) in vehicles"
              :key="index"
            >
              {{ vehicle.vehicle }}
            </b-form-select-option>
          </template>
        </b-form-select>
        <b-form-input
          type="text"
          v-model="notes"
          placeholder="Anmärkning"
        ></b-form-input>
        <b-datepicker
          class="mt-2"
          start-weekday="1"
          v-model="startDate"
          placeholder="Fr o m"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          :class="{ 'is-invalid': $v.startDate.$error }"
        ></b-datepicker>
      </b-form-group>
      <b-button variant="success" @click="addDriverVehicle()">Spara</b-button>
    </b-form>
    <div class="dv-lists">
      <table class="table table-striped" v-if="driverVehicles.length > 0">
        <thead>
          <tr>
            <th scope="col">Fordonstyp</th>
            <th scope="col">Anmärkning</th>
            <th colspan="col">Startdatum</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(dv, dvKey) in driverVehicles" :key="dvKey">
            <td>{{ dv.vehicle }}</td>
            <td>{{ dv.note }}</td>
            <td>{{ dv.startDate }}</td>
          </tr>
        </tbody>
      </table>
      <div class="alert alert-primary" v-else>Inga fordon inlagda.</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "vehicles",
  props: ["personID"],
  data() {
    return {
      vehicle: null,
      notes: null,
      startDate: null,
      driverVehicles: [],
      submitStatus: false,
    };
  },

  validations: {
    vehicle: { required },
    startDate: { required },
  },

  computed: {
    ...mapState(["baseUrl", "apiKey", "vehicles"]),
  },

  mounted() {
    this.$store.dispatch("fetchVehicles");
    this.fetchDriverVehicles();
  },

  methods: {
    fetchDriverVehicles() {
      this.$http
        .get(
          this.baseUrl +
            "api/get.php?type=vehicles&personID=" +
            this.personID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.driverVehicles = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    addDriverVehicle() {
      this.$v.vehicle.$touch();
      this.$v.startDate.$touch();
      if (this.$v.vehicle.$invalid || this.$v.startDate.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";

        var formData = new FormData();
        formData.append("vehicleID", this.vehicle.vehicleID);
        if (this.notes != null && this.notes.length > 0) {
          formData.append("note", this.notes);
        }
        formData.append("startDate", this.startDate);
        formData.append("personID", this.personID);

        this.$http
          .post(
            this.baseUrl +
              "api/edit.php?type=newDriverVehicle&key=" +
              this.apiKey,
            formData
          )
          .then((response) => {
            if (response.data.status == "ok") {
              this.$store
                .dispatch("fetchPersonVehicles", this.personID)
                .then(() => this.$store.commit("CHECK_ENABLED"));
              this.submitStatus = "OK";
              this.vehicle = null;
              this.notes = null;
              this.startDate = null;
              this.fetchDriverVehicles();
              this.$v.$reset();
              this.$notify({
                group: "alerts",
                title: "Meddelande",
                text: "Ett fordon har lagts till.",
                type: "success",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.$notify({
              group: "alerts",
              title: "Meddelande",
              text: "Det gick inte att spara!",
              type: "error",
            });
          });
      }
    },
  },
};
</script>

<style lang="scss">
.vehicles {
  .dv-lists {
    margin: 25px 0;
    border-top: 1px solid #ddd;
    padding: 25px 0;
  }
}
</style>