<template>
  <div class="rental">
    <b-container>
      <input type="file" id="file" ref="file" @change="handleUpload()" />
      <button @click="saveFile()">Ladda upp</button>
      <h3 style="margin-top: 24px">Filer</h3>
      <ul v-if="files.length > 0">
        <li v-for="(f, index) in files" :key="index">
          <a :href="baseUrl + 'api/get.php?type=file&fileID=' + f.fileID + '&key=' + apiKey">{{
            f.filename
          }}</a>
        </li>
      </ul>
    </b-container>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "documents",
  props: ["personID"],
  computed: {
    ...mapState(["baseUrl", "apiKey", "personVehicles"]),
  },
  data() {
    return {
      file: "",
      files: [],
    };
  },
  mounted() {
    this.fetchFiles();
  },
  methods: {
    fetchFiles() {
      this.$http
        .get(
          this.baseUrl +
            "api/get.php?type=files&personID=" +
            this.personID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          this.files = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleUpload() {
      this.file = this.$refs.file.files[0];
    },
    saveFile() {
      let formData = new FormData();
      formData.append("file", this.file);
      this.$http
        .post(
          this.baseUrl +
            "api/edit.php?type=addFile&personID=" +
            this.personID +
            "&key=" +
            this.apiKey,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then(function () {
          this.fetchFiles();
          this.file = '';
        });
    },
  },
};
</script>

<style lang="scss">
</style>