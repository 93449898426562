<template>
    <div class="main w-100">
        <navbar :title="'Utbildningar'" />
        <div class="educations p-3">
            <div class="card mb-3">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <!-- <h4>Utbildningar</h4> -->
                            <h4>&nbsp;</h4>
                        </div>
                        <div class="col-md-6 text-right">
                            <button
                                type="button"
                                class="btn btn-success"
                                @click="showModal=true"
                            >
                                <font-awesome-icon icon="plus-circle" /> Ny utbildning
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="edu-lists">
                <table class="table table-hover" v-if="educations.length > 0">
                    <tbody>
                        <tr v-for="(education, eduKey) in educations" :key="eduKey">
                            <td class="title">{{ education.education }}</td>
                            <td class="actions">
                                <span class="edit">
                                    <font-awesome-icon icon="edit" />
                                </span>
                                <span class="delete">
                                    <font-awesome-icon icon="trash-alt" />
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="alert alert-primary" v-else>
                    Inga utbildningar inlagda
                </div>
            </div>
        </div>
        <template>
            <b-modal
                id="modal-1"
                size="lg"
                :title="'Lägg till ny utbilding'"
                :hide-footer="true"
                v-model="showModal"
            >
                <div class="form-group">
                    <input 
                        type="text"
                        class="form-control form-control-lg"
                        placeholder="Utbildning"
                        v-model="educationName"
                        :class="{ 'is-invalid': $v.educationName.$error }"
                    >
                    <div class="invalid-feedback" v-if="!$v.educationName.required">
                        Namn på utbildningen måste anges.
                    </div>
                </div>
                <button
                    type="button"
                    class="btn btn-success btn-block btn-lg"
                    @click="saveEducation()"
                >
                    Spara
                </button>
            </b-modal>
        </template>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import { mapState } from 'vuex'
import { required } from "vuelidate/lib/validators";
export default {
    name: 'Educations',
    data() {
        return {
            title: 'Utbildningar',
            showModal: false,
            educationName: null,
            submitStatus: null
        }
    },
    
    validations: {
        educationName: { required },
    },

    computed: {
        ...mapState(['baseUrl', 'apiKey', 'educations'])
    },

    components: {
        Navbar
    },

    mounted() {
        this.$store.dispatch('fetchEducations');
    },

    methods: {
        saveEducation() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.submitStatus = "ERROR";
            } else {
                this.submitStatus = "PENDING";
                var formData = new FormData()
                formData.append('education', this.educationName)
                this.$http.post(this.baseUrl+'api/edit.php?type=addEducation&key='+this.apiKey, formData)
                .then((response) => {
                    if (response.data.status == 'ok') {
                        this.educationName = null
                        this.showModal = false
                        this.$store.dispatch('fetchEducations')
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.educations {
    .edu-lists {
        background: #fff;
    }

    .table {
        .title {
            color: #626567;
            font-weight: 600;
            font-size: 15px;
            &:hover {
                color: #A6ACAF;
            }
        }
        .actions {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            span {
                margin-right: 15px;
                cursor: pointer;
                color: #626567;
                font-size: 15px;
            }
            .edit:hover {
                color: #3498DB;
            }
            .delete:hover {
                color: #E74C3C;
            }
        }
    }
}
</style>