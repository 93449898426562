<template>
  <div class="main w-100">
    <navbar :title="'Start'" />
    <div class="home p-3">
      <table class="table" v-if="list != null">
        <thead>
          <tr>
            <th colspan>Person</th>
            <th colspan>Datum</th>
            <th>Kurs/intyg</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(r, index) in list" :key="index">
            <td>
              <router-link
                :to="{
                  name: 'Personaldetails',
                  params: { personID: r.staffID },
                }"
                >{{ r.firstName }} {{ r.lastName }}
              </router-link>
            </td>
            <td>
              <span class="status">
                <span
                  :class="{
                    red: getMonths(r.expireDate) <= 0,
                    orange:
                      getMonths(r.expireDate) > 0 &&
                      getMonths(r.expireDate) <= 1,
                    yellow:
                      getMonths(r.expireDate) > 1 &&
                      getMonths(r.expireDate) <= 4,
                    green: getMonths(r.expireDate) > 4,
                  }"
                  >&nbsp;</span
                >&nbsp;{{ r.expireDate }}</span
              >
            </td>
            <td>{{ r.education }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "Home",
  computed: {
    ...mapState(["baseUrl", "apiKey"]),
  },
  data() {
    return {
      list: [],
    };
  },

  components: {
    Navbar,
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    fetchList() {
      this.$http
        .get(this.baseUrl + "api/get.php?type=expireList&key=" + this.apiKey)
        .then((response) => {
          this.list = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMonths(date) {
      return moment(date).diff(moment(), "months", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .red,
  .orange,
  .yellow,
  .green {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .percent {
    font-weight: 600;
    font-size: 14px;
  }
  .green {
    background: #27ae60;
  }
  .yellow {
    background: #f1c40f;
  }
  .orange {
    background: #e67e22;
  }
  .red {
    background: #e74c3c;
  }
}
</style>