<template>
    <div class="main w-100">
        <navbar :title="'Inställningar'" />
        <div class="profile p-3">
            <div class="row">
                <div class="col-md-6 col-lg-5">
                    <div class="card">
                        <div class="card-body">
                            <h3 class="mb-3">Byt lösenord</h3>
                            <div class="alert alert-danger errors" role="alert" v-if="errors.length > 0">
                                <p v-for="(error, errorKey) in errors" :key="errorKey">{{ error }}</p>
                            </div>
                            <div class="alert alert-success" role="alert" v-if="successMessage != null && errors.length == 0">
                                <span>{{ successMessage }}</span>
                            </div>
                            <div class="form-group">
                                <input 
                                    type="password" 
                                    class="form-control form-control-lg" 
                                    placeholder="Ange nuvarande lösenord"
                                    v-model="password"
                                >
                            </div>
                            <div class="form-group">
                                <input 
                                    type="password" 
                                    class="form-control form-control-lg" 
                                    placeholder="Nytt lösenord"
                                    v-model="newPassword"
                                >
                            </div>
                            <div class="form-group">
                                <input 
                                    type="password" 
                                    class="form-control form-control-lg" 
                                    placeholder="Bekräfta nytt lösenord"
                                    v-model="confirmPassword"
                                >
                            </div>
                            <button 
                                type="button" 
                                class="btn btn-success btn-lg btn-block"
                                @click="update()"
                            >
                                Skicka
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { isPasswordValid, isPasswordConfirmed } from '@/_helpers/validations'
import Navbar from '@/components/Navbar.vue'
export default {
    name: 'Profile',
    props: ['title'],
    data() {
        return {
            password: null,
            newPassword: null,
            confirmPassword: null
        }
    },

    computed: {
        ...mapState(['baseUrl', 'apiToken', 'errors', 'successMessage']),
    },

    components: {
        Navbar
    },

    methods: {
        update() {
            this.$store.commit('ERRORS', null)
            if (isPasswordValid(this.password)) {
                this.$store.commit('ERRORS', 'Ange nuvarande lösenord')
            }

            if (isPasswordConfirmed(this.newPassword, this.confirmPassword)) {
                this.$store.commit('ERRORS', isPasswordConfirmed(this.newPassword, this.confirmPassword))
            }

            // user, pass, newpass och newpass2

            if (this.errors.length == 0) {
                var formData = new FormData()
                formData.append('user', this.user)
                formData.append('pass', this.password)
                formData.append('newpass', this.newPassword)
                formData.append('newpass2', this.confirmPassword)
                this.$store.dispatch('changePassword', formData).then((res) => {
                    if (res) {
                        this.password = null
                        this.newPassword = null
                        this.confirmPassword = null
                    } else {
                        this.$store.commit('ERRORS', '')
                    }
                })
            }
        }
    }
}
</script>