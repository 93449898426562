var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ki"},[_c('b-row',{staticClass:"bbox"},[_c('b-col',[_c('h3',[_vm._v("Förarbevis")]),_c('b-form',[_c('b-form-group',{staticClass:"form-inline",attrs:{"label":"Förarbevisnummer"}},[_c('b-form-select',{staticClass:"mr-2",model:{value:(_vm.fbCountry),callback:function ($$v) {_vm.fbCountry=$$v},expression:"fbCountry"}},[_c('b-form-select-option',{attrs:{"value":"SE","se":""}},[_vm._v("SE")])],1),_c('b-form-input',{class:{ 'is-invalid': _vm.$v.fbNumber.$error },attrs:{"type":"number","placeholder":"Nummer","disabled":_vm.driverLicense != null},model:{value:(_vm.fbNumber),callback:function ($$v) {_vm.fbNumber=$$v},expression:"fbNumber"}})],1),_c('b-form-group',{staticClass:"form-inline",attrs:{"label":"Giltighetstid"}},[_c('b-form-datepicker',{staticClass:"mr-2",class:{ 'is-invalid': _vm.$v.fbStartDate.$error },staticStyle:{"float":"left"},attrs:{"start-weekday":"1","placeholder":"Fr o m","date-format-options":{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            },"disabled":_vm.driverLicense != null},model:{value:(_vm.fbStartDate),callback:function ($$v) {_vm.fbStartDate=$$v},expression:"fbStartDate"}}),_c('b-form-datepicker',{class:{ 'is-invalid': _vm.$v.fbEndDate.$error },staticStyle:{"float":"left"},attrs:{"start-weekday":"1","placeholder":"T o m","date-format-options":{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            },"disabled":_vm.driverLicense != null},model:{value:(_vm.fbEndDate),callback:function ($$v) {_vm.fbEndDate=$$v},expression:"fbEndDate"}})],1),_c('b-button',{attrs:{"variant":"success","disabled":_vm.driverLicense != null},on:{"click":function($event){return _vm.saveDriverLicense()}}},[_vm._v("Spara")])],1)],1),_c('b-col',[_c('h3',[_vm._v("Åtgärder")]),_c('ul',[_c('li',[_vm._v("Nytt förarbevis")]),_c('li',[_vm._v("Ändra status")])])])],1),_c('b-row',{staticClass:"bbox"},[_c('b-col',[_c('h3',[_vm._v("Kompletterande intyg")]),_c('b-row',[_c('b-col',[_c('b-form',[_c('b-form-group',{staticClass:"form-inline",attrs:{"label":"Infrastruktur"}},[_c('b-form-input',{staticClass:"mr-2",class:{ 'is-invalid': _vm.$v.infrastructure.$error },attrs:{"type":"text","placeholder":"Infrastruktur"},model:{value:(_vm.infrastructure),callback:function ($$v) {_vm.infrastructure=$$v},expression:"infrastructure"}}),_c('b-form-input',{attrs:{"type":"text","placeholder":"Anmärkning"},model:{value:(_vm.infraNotes),callback:function ($$v) {_vm.infraNotes=$$v},expression:"infraNotes"}}),_c('b-datepicker',{staticClass:"mt-2",class:{ 'is-invalid': _vm.$v.infraDate.$error },attrs:{"start-weekday":"1","placeholder":"Fr o m","date-format-options":{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }},model:{value:(_vm.infraDate),callback:function ($$v) {_vm.infraDate=$$v},expression:"infraDate"}}),_c('b-button',{staticClass:"mt-2",attrs:{"variant":"success"},on:{"click":function($event){return _vm.saveSupplementaryCertificate()}}},[_vm._v("Spara")])],1)],1)],1),_c('b-col',[(_vm.supplementaryCertificates.length > 0)?_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.supplementaryCertificates,"fields":_vm.scFields},scopedSlots:_vm._u([{key:"cell(note)",fn:function(data){return [_vm._v(" "+_vm._s(data.value == "null" ? "-" : data.value)+" ")]}}],null,false,70511048)}):_vm._e()],1)],1),_c('hr'),_c('b-row',[_c('b-col',[_c('b-form',[_c('b-form-group',{staticClass:"form-inline",attrs:{"label":"Språk"}},[_c('b-form-input',{staticClass:"mr-2",class:{ 'is-invalid': _vm.$v.language.$error },attrs:{"type":"text","placeholder":"Språk"},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}}),_c('b-form-input',{attrs:{"type":"text","placeholder":"Anmärkning"},model:{value:(_vm.langNotes),callback:function ($$v) {_vm.langNotes=$$v},expression:"langNotes"}}),_c('b-datepicker',{staticClass:"mt-2",class:{ 'is-invalid': _vm.$v.langDate.$error },attrs:{"start-weekday":"1","placeholder":"Fr o m","date-format-options":{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }},model:{value:(_vm.langDate),callback:function ($$v) {_vm.langDate=$$v},expression:"langDate"}}),_c('b-button',{staticClass:"mt-2",attrs:{"variant":"success"},on:{"click":function($event){return _vm.saveLanguage()}}},[_vm._v("Spara")])],1)],1)],1),_c('b-col',[(_vm.languages.length > 0)?_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.languages,"fields":_vm.languageFields},scopedSlots:_vm._u([{key:"cell(note)",fn:function(data){return [_vm._v(" "+_vm._s(data.value == "null" ? "-" : data.value)+" ")]}}],null,false,70511048)}):_vm._e()],1)],1),_c('hr'),_c('b-row',[_c('b-col',[_c('b-form',[_c('b-form-group',{staticClass:"form-inline",attrs:{"label":"Fordon"}},[_c('b-form-select',{staticClass:"mr-2",class:{ 'is-invalid': _vm.$v.vehicle.$error },on:{"change":_vm.vehicleChanged},model:{value:(_vm.vehicle),callback:function ($$v) {_vm.vehicle=$$v},expression:"vehicle"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Välj fordonstyp")]),(_vm.personVehicles.length > 0)?_vm._l((_vm.personVehicles),function(vehicle,vehicleKey){return _c('b-form-select-option',{key:vehicleKey,attrs:{"value":vehicle,"disabled":vehicle.disabled}},[_vm._v(" "+_vm._s(vehicle.vehicle)+" ("+_vm._s(vehicle.note)+") - "+_vm._s(vehicle.startDate)+" ")])}):_vm._e()],2),_c('b-form-input',{attrs:{"type":"text","placeholder":"Anmärkning"},model:{value:(_vm.vehicleNotes),callback:function ($$v) {_vm.vehicleNotes=$$v},expression:"vehicleNotes"}}),_c('b-datepicker',{staticClass:"mt-2",class:{ 'is-invalid': _vm.$v.vehicleStartDate.$error },attrs:{"start-weekday":"1","placeholder":"Fr o m","date-format-options":{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }},model:{value:(_vm.vehicleStartDate),callback:function ($$v) {_vm.vehicleStartDate=$$v},expression:"vehicleStartDate"}}),_c('b-button',{staticClass:"mt-2",attrs:{"variant":"success"},on:{"click":function($event){return _vm.saveVehicle()}}},[_vm._v("Spara")])],1)],1)],1),_c('b-col',[(_vm.driverVehicles.length > 0)?_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.driverVehicles,"fields":_vm.driverVehiclesFields}}):_vm._e()],1)],1),_c('hr'),_c('b-row',[_c('b-col',[_c('b-form',[_c('b-form-group',{staticClass:"form-inline",attrs:{"label":"Extra personuppgifter"}},[_c('b-form-input',{staticClass:"mr-2",class:{ 'is-invalid': _vm.$v.kiCity.$error },attrs:{"placeholder":"Födelseort"},model:{value:(_vm.kiCity),callback:function ($$v) {_vm.kiCity=$$v},expression:"kiCity"}}),_c('b-form-input',{class:{ 'is-invalid': _vm.$v.kiNationality.$error },attrs:{"placeholder":"Nationalitet"},model:{value:(_vm.kiNationality),callback:function ($$v) {_vm.kiNationality=$$v},expression:"kiNationality"}})],1),_c('b-form-group',{attrs:{"label":"Förarkategorier"}},[_c('b-form-checkbox-group',{attrs:{"options":_vm.catA,"value-field":"text","text-field":"value"},model:{value:(_vm.driverA),callback:function ($$v) {_vm.driverA=$$v},expression:"driverA"}}),_c('b-form-checkbox-group',{attrs:{"options":_vm.catB,"value-field":"text","text-field":"value"},model:{value:(_vm.driverB),callback:function ($$v) {_vm.driverB=$$v},expression:"driverB"}})],1),_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.saveExtraPersonalInfo()}}},[_vm._v("Spara")])],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',[_c('h3',[_vm._v("Åtgärder")]),_c('ul',[_c('li',[_vm._v("Kontrollera behörigheter")]),_c('li',[_vm._v("Återkalla KI")]),_c('li',[_vm._v("Utfärda KI")]),_c('li',[_vm._v("Skriv ut kopia")])])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }