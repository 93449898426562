import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Staffs from '../views/Staffs.vue'
import Educations from '../views/Educations.vue'
import Qualifications from '../views/Qualifications.vue'
import Vehicles from '../views/Vehicles.vue'
import Reports from '../views/Reports.vue'
import Profile from '../views/Profile.vue'
import Login from '../views/Login.vue'
import Person from '../views/persons/Index.vue'
import Certificate from '../views/Certificate.vue'
import Share from '../views/Share.vue'
import { isAuthenticated } from '@/_helpers/_auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/personal',
    name: 'Staffs',
    component: Staffs,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/personal/:staffID',
    name: 'show-staff',
    component: Staffs,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/person',
    name: 'Person',
    component: Person,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/person/:personID',
    name: 'Personaldetails',
    component: Person,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/utbildningar',
    name: 'Educations',
    component: Educations,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/kompetenser',
    name: 'Qualifications',
    component: Qualifications,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/fordon',
    name: 'Vehicles',
    component: Vehicles,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/rapporter',
    name: 'Reports',
    component: Reports,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/dela/:id',
    name: 'Personal',
    component: Share,
    props: true
  },
  {
    path: '/installningar',
    name: 'Profile',
    component: Profile,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/intyg',
    name: 'Certificate',
    component: Certificate,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresGuest: false }
  },

  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "Start",
    component: Home,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !isAuthenticated()) {
    next({
      name: 'Login',
      query: { redirect: to.fullPath }
    })
  } else if (to.name === 'Login' && isAuthenticated()) {
    next({ name: 'Home' })
  } else {
    next()
  }
})

export default router
