var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"training"},[_c('h4',[_vm._v("Utbildning")]),_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('b-form',[_c('b-form-group',{attrs:{"label":"Kurs"}},[_c('b-form-select',{class:{ 'is-invalid': _vm.$v.course.$error },scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Välj kurs")])]},proxy:true}]),model:{value:(_vm.course),callback:function ($$v) {_vm.course=$$v},expression:"course"}},[(_vm.courses.length > 0)?_vm._l((_vm.courses),function(course,courseKey){return _c('b-form-select-option',{key:courseKey,attrs:{"value":course}},[_vm._v(" "+_vm._s(course.education)+" ")])}):_vm._e()],2)],1),_c('b-form-group',{attrs:{"label":"Kursdatum"}},[_c('b-form-datepicker',{class:{ 'is-invalid': _vm.$v.startDate.$error },staticStyle:{"float":"left"},attrs:{"start-weekday":"1","placeholder":"Fr o m","date-format-options":{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.addPersonEducation()}}},[_vm._v("Spara")])],1)],1),_c('b-col',{attrs:{"cols":"7"}},[(_vm.educations.length > 0)?_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.educations,"fields":_vm.educationFields}}):_vm._e()],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('h4',[_vm._v("Kompetenser")]),_c('b-form',[_c('b-form-group',{attrs:{"label":"Kompetens"}},[_c('b-form-select',{class:{ 'is-invalid': _vm.$v.qualification.$error },scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Välj Kompetens")])]},proxy:true}]),model:{value:(_vm.qualification),callback:function ($$v) {_vm.qualification=$$v},expression:"qualification"}},[(_vm.qualifications.length > 0)?_vm._l((_vm.qualifications),function(qualification,qKey){return _c('b-form-select-option',{key:qKey,attrs:{"value":qualification}},[_vm._v(" "+_vm._s(qualification.qualification)+" ")])}):_vm._e()],2)],1),_c('b-form-group',{attrs:{"label":"Kompetensdatum"}},[_c('b-form-datepicker',{class:{ 'is-invalid': _vm.$v.qStartDate.$error },staticStyle:{"float":"left"},attrs:{"start-weekday":"1","placeholder":"F o m","date-format-options":{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }},model:{value:(_vm.qStartDate),callback:function ($$v) {_vm.qStartDate=$$v},expression:"qStartDate"}})],1),_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.addPersonQualification()}}},[_vm._v("Spara")])],1)],1),_c('b-col',{attrs:{"cols":"7"}},[(_vm.personQualifications.length > 0)?_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.personQualifications,"fields":_vm.qualificationFields}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }