import Vue from 'vue'
import Vuex from 'vuex'
// import axios from 'axios'
import axios from '../axios'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    baseUrl: process.env.VUE_APP_URL,
    authenticated: false,
    apiKey: JSON.parse(localStorage.getItem('_kr_token')) || null,
    errors: [],
    successMessage: null,
    educations: [],
    qualifications: [],
    vehicles: [],
    personVehicles: [],
    driverVehicles: []
  },
  mutations: {
    AUTHENTICATED(state) {
      state.authenticated = true
    },

    AUTH_LOGIN(state, payload) {
      state.authenticated = true
      state.apiKey = payload.key
    },

    AUTH_LOGOUT(state) {
      state.authenticated = false
    },

    ERRORS(state, payload) {
      if (payload == null) {
        state.errors = []
      } else {
        state.errors.push(payload)
      }
    },

    SUCCESS_MESSAGE(state, payload) {
      state.successMessage = payload
    },

    FETCH_QUALIFICATIONS(state, payload) {
      state.qualifications = payload
    },

    FETCH_EDUCATIONS(state, payload) {
      state.educations = payload
    },

    QUALIFICATION_SELECTED(state, payload) {
      state.qualifications.map((q) => {
        if (q.qualificationID == payload) {
          q.selected = true
          console.log(q)
        } else {
          q.selected = false
        }
      })
    },

    FETCH_VEHICLES(state, payload) {
      state.vehicles = payload
    },

    FETCH_PERSON_VEHICLES(state, payload) {
      state.personVehicles = payload
    },

    FETCH_DRIVER_VEHICLES(state, payload) {
      state.driverVehicles = payload
    },

    CHECK_ENABLED(state) {
      state.personVehicles.map((vehicle) => {
        state.driverVehicles.map((dv) => {
          if (vehicle.vehicle == dv.roleValue) {
            vehicle.disabled = true;
          }
        });
      });
    }
  },
  actions: {
    initialiseStore({state, commit, dispatch}) {
      if (state.apiKey !== null) {
        commit('AUTHENTICATED')
      } else {
        dispatch('logout')
      }
    },

    login({ commit, state }, formData) {
      return new Promise((resolve, reject) => {
        axios.post(state.baseUrl + 'api/auth.php', formData)
          .then((response) => {
            if (response.data.status == 'ok') {
              commit('AUTH_LOGIN', response.data.data)
              commit('ERRORS', null)
              resolve(response)
            } else {
              commit('ERRORS', response.data.error)
            }
          })
          .catch((error) => {
            commit('AUTH_LOGOUT')
            reject(error)
          })
      })
    },

    logout({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.apiKey != null) {
          var postdata = new FormData()
          postdata.append("key", state.apiKey)

          axios.post(state.baseUrl + 'api/logout.php', postdata)
          .then((response) => {
            commit('AUTH_LOGOUT')
            resolve(response)
          })
          .catch((error) => {
            commit('AUTH_LOGOUT')
            reject(error)
          })
        }
      })
    },

    changePassword({state, commit}, formData) {
      return new Promise((resolve, reject) => {
        axios.post(state.baseUrl + 'api/settings.php?key=' + state.apiKey, formData)
        .then((response) => {
          if (response.data.status == 'ok') {
            commit('ERRORS', null)
            // commit('AUTH_LOGOUT')
            commit('SUCCESS_MESSAGE', 'Lösenord uppdaterat')
            resolve(true)
          } else {
            reject(false)
          }
        })
        .catch(() => {
          // commit('AUTH_LOGOUT')
          reject(false)
        })
      })
    },

    fetchQualifications({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(state.baseUrl + 'api/get.php?type=qualifications&key=' + state.apiKey)
          .then((response) => {
            if (response.data.status == 'ok') {
              commit('FETCH_QUALIFICATIONS', response.data.data)
              resolve(response)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    fetchEducations({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(state.baseUrl + 'api/get.php?type=educations&key=' + state.apiKey)
          .then((response) => {
            if (response.data.status == 'ok') {
              commit('FETCH_EDUCATIONS', response.data.data)
              resolve(response)
            }
          })
          .catch((error) => {
              reject(error)
          })
      })
    },

    fetchVehicles({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(state.baseUrl +'api/get.php?type=vehicles&key='+state.apiKey)
        .then((response) => {
          if (response.data.status == 'ok') {
            commit('FETCH_VEHICLES', response.data.data)
            resolve(response)
          }
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    fetchPersonVehicles({ state, commit }, personID) {
      return new Promise((resolve, reject) => {
        axios.get(state.baseUrl + 'api/get.php?type=vehicles&personID='+personID+'&key=' + state.apiKey)
          .then((response) => {
            if (response.data.status == 'ok') {
              commit('FETCH_PERSON_VEHICLES', response.data.data)
              resolve(response)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    fetchDriverVehicles({state, commit}, personID) {
      return new Promise((resolve, reject) => {
        axios.get(state.baseUrl+'api/get.php?type=driverVehicles&personID='+personID+'&key=' +state.apiKey)
          .then((response) => {
            if (response.data.status == "ok") {
              commit('FETCH_DRIVER_VEHICLES', response.data.data)
              resolve(response)
            }
          })
          .catch((error) => {
            reject(error);
          });
      })
    }
  },
})

store.subscribe((mutation) => {
  if (mutation.type == 'AUTH_LOGIN') {
    localStorage.setItem('_kr_token', JSON.stringify(mutation.payload.key))
  }

  if (mutation.type == 'AUTH_LOGOUT') {
    localStorage.removeItem('_kr_token')
  }
})

export default store