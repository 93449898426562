var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main w-100"},[_c('navbar',{attrs:{"title":'Start'}}),_c('div',{staticClass:"home p-3"},[(_vm.list != null)?_c('table',{staticClass:"table"},[_vm._m(0),_c('tbody',_vm._l((_vm.list),function(r,index){return _c('tr',{key:index},[_c('td',[_c('router-link',{attrs:{"to":{
                name: 'Personaldetails',
                params: { personID: r.staffID },
              }}},[_vm._v(_vm._s(r.firstName)+" "+_vm._s(r.lastName)+" ")])],1),_c('td',[_c('span',{staticClass:"status"},[_c('span',{class:{
                  red: _vm.getMonths(r.expireDate) <= 0,
                  orange:
                    _vm.getMonths(r.expireDate) > 0 &&
                    _vm.getMonths(r.expireDate) <= 1,
                  yellow:
                    _vm.getMonths(r.expireDate) > 1 &&
                    _vm.getMonths(r.expireDate) <= 4,
                  green: _vm.getMonths(r.expireDate) > 4,
                }}),_vm._v(" "+_vm._s(r.expireDate))])]),_c('td',[_vm._v(_vm._s(r.education))])])}),0)]):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"colspan":""}},[_vm._v("Person")]),_c('th',{attrs:{"colspan":""}},[_vm._v("Datum")]),_c('th',[_vm._v("Kurs/intyg")])])])}]

export { render, staticRenderFns }