<template>
  <div class="main w-100">
    <navbar :title="'Reports'" />
    <div class="reports p-3">
        <b-container>
      <h3>Hälsoundersökningar</h3>
      <table class="table" v-if="healthList != null">
        <thead>
          <tr>
            <th>Person</th>
            <th>Datum</th>
            <th>Dispens utgår</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(r, index) in healthList" :key="index">
            <td>
              <router-link
                :to="{
                  name: 'Personaldetails',
                  params: { personID: r.staffID },
                }"
                >{{ r.firstName }} {{ r.lastName }}
              </router-link>
            </td>
            <td>
              <span class="status">
                <span
                  :class="{
                    red: getMonths(r.expireDate) <= 0,
                    orange:
                      getMonths(r.expireDate) > 0 &&
                      getMonths(r.expireDate) <= 1,
                    yellow:
                      getMonths(r.expireDate) > 1 &&
                      getMonths(r.expireDate) <= 4,
                    green: getMonths(r.expireDate) > 4,
                  }"
                  >&nbsp;</span
                >&nbsp;{{ r.expireDate }}</span
              >
            </td>
            <td>{{ r.exemptExpire }}</td>
          </tr>
        </tbody>
      </table>
      <h3>Utbildningar</h3>
      <table class="table" v-if="educationList != null">
        <thead>
          <tr>
            <th>Person</th>
            <th>Utbildningar</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(r, index) in educationList" :key="index">
            <td>
              <router-link
                :to="{
                  name: 'Personaldetails',
                  params: { personID: r.staffID },
                }"
                >{{ r.firstName }} {{ r.lastName }}
              </router-link>
            </td>
            <td>
                  <ul>
                      <li v-for="(d, index2) in r.data" :key="index2">
              <span class="status">
                <span
                  :class="{
                    red: getMonths(d.expireDate) <= 0,
                    orange:
                      getMonths(d.expireDate) > 0 &&
                      getMonths(d.expireDate) <= 1,
                    yellow:
                      getMonths(d.expireDate) > 1 &&
                      getMonths(d.expireDate) <= 4,
                    green: getMonths(d.expireDate) > 4,
                  }"
                  >&nbsp;</span
                >&nbsp;{{ d.expireDate }} {{ d.education }}</span
              ></li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <h3>Kompetenser</h3>
      <table class="table" v-if="qualificationList != null">
        <thead>
          <tr>
            <th>Person</th>
            <th>Utbildningar</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(r, index) in qualificationList" :key="index">
            <td>
              <router-link
                :to="{
                  name: 'Personaldetails',
                  params: { personID: r.staffID },
                }"
                >{{ r.firstName }} {{ r.lastName }}
              </router-link>
            </td>
            <td>
                  <ul>
                      <li v-for="(d, index2) in r.data" :key="index2">
{{ d.qualification }}</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
        </b-container>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "Reports",
  props: ["title"],
  components: {
    Navbar,
  },
  data() {
    return {
      healthList: [],
      educationList: [],
      qualificationList: [],
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
  },
  mounted() {
    this.fetchHealth();
    this.fetchEducation();
    this.fetchQualification();
  },
  methods: {
    getMonths(date) {
      return moment(date).diff(moment(), "months", true);
    },
    fetchHealth() {
      this.$http
        .get(this.baseUrl + "api/get.php?type=reportHealth&key=" + this.apiKey)
        .then((response) => {
          this.healthList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchEducation() {
      this.$http
        .get(
          this.baseUrl + "api/get.php?type=reportEducations&key=" + this.apiKey
        )
        .then((response) => {
          this.educationList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchQualification() {
      this.$http
        .get(
          this.baseUrl +
            "api/get.php?type=reportQualifications&key=" +
            this.apiKey
        )
        .then((response) => {
          this.qualificationList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .red,
  .orange,
  .yellow,
  .green {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .percent {
    font-weight: 600;
    font-size: 14px;
  }
  .green {
    background: #27ae60;
  }
  .yellow {
    background: #f1c40f;
  }
  .orange {
    background: #e67e22;
  }
  .red {
    background: #e74c3c;
  }
}

ul {
    list-style-type: none;
}
</style>