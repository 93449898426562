<template>
  <div class="login w-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-5 col-lg-6">
                <div class="card card-lg mb-5">
                <div class="card-body">
                    <div class="header text-center">
                    <h2>Logga in</h2>
                    </div>
                    <div class="alert alert-danger errors" role="alert" v-if="errors.length > 0">
                        <p v-for="(error, errorKey) in errors" :key="errorKey">{{ error }}</p>
                    </div>
                    <form class="auth">
                    <div class="form-group">
                        <label for="email">Användarnamn</label>
                        <input
                        type="email"
                        class="form-control form-control-lg"
                        v-model="email"
                        placeholder="E-postadress"
                        />
                    </div>
                    <div class="form-group">
                        <label for="password">Lösenord</label>
                        <input
                        type="password"
                        class="form-control form-control-lg"
                        id="password"
                        placeholder="Lösenord"
                        v-model="password"
                        />
                    </div>
                    <button class="btn btn-primary btn-block btn-lg" @click.prevent="login">
                        Logga in
                    </button>
                    </form>
                </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isEmailValid, isPasswordValid } from '@/_helpers/validations'
export default {
  name: "Login",
  data() {
    return {
      email: null,
      password: null,
      submitStatus: null
    };
  },

  computed: {
      ...mapState(['baseUrl', 'apiToken', 'errors']),
  },

  methods: {
    login() {
        this.$store.commit('ERRORS', null)
        if (isEmailValid(this.email)) {
            this.$store.commit('ERRORS', isEmailValid(this.email))
        }
        if (isPasswordValid(this.password)) {
            this.submitStatus = "ERROR";
            this.$store.commit('ERRORS', isPasswordValid(this.password))
        } 
        if (this.errors.length == 0) {
            var postdata = new FormData();
            postdata.append("user", this.email);
            postdata.append("pass", this.password);
            this.$store.dispatch("login", postdata).then((res) => {
              console.log(res)
                if (res.data.status == "ok") {
                    this.email = null;
                    this.password = null;
                    this.$router.push({ name: "Home" });
                }
            }).catch((errors) => {
                console.log(errors)
            })
        }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  background: #f1f1f1;
  height: 100%;
  padding: 200px 0;
  .card {
    box-shadow: 0 6px 12px rgba(140, 152, 164, 0.075);
    .card-body {
      padding: 2rem;
      .header {
        padding-bottom: 20px;
      }
    }
  }
  // .login-form {
  //     width: 30%;
  //     background: #fff;
  //     padding: 80px 35px;
  //     margin: 0 auto;
  //     border-radius: 5px;
  //     box-shadow: 0 6px 12px rgba(140,152,164,.075);
  //     h3 {
  //         text-align: center;
  //     }
  // }
}
</style>