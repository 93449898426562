<template>
    <nav class="navbar">
        <a class="navbar-brand">{{ title }}</a>
        <!-- <form class="form-inline">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Dropdown link
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <a class="dropdown-item" href="#">Something else here</a>
            </div>
        </form> -->
    </nav>
</template>

<script>
export default {
    name: 'Navbar',
    props: ['title'],
    data() {
        return {
            // title: 'Kompetensregister'
        }
    }
}
</script>

<style lang="scss" scoped>
.navbar {
    border-bottom: 1px solid #E5E8E8;
    background: #fff;
    .navbar-brand {
        font-weight: 600;
        color: #99A3A4;
    }
}
</style>