import router from './router'
import store from './store'
import axios from 'axios';

// Axios request interceptors
axios.interceptors.request.use(
    (request) => {

        // console.log(request);

        return request;
    }
);

// Axios response interceptors
axios.interceptors.response.use(
    (response) => {
        if (response.data.status == 'error') {
            console.log('Found errors in data response...')
            // store.dispatch('logout').then(() => {
            //     router.push({ name: 'Login' })
            // })
            setTimeout(() => {
                store.commit('ERRORS', response.data.data)
            }, 100)
        }
        return response;
    },
    (error) => {
        if (error.response) {
            /*
            * The request was made and the server responded with a
            * status code that falls out of the range of 2xx
            */
            console.log('The request was made and the server responded with error')
        } else if (error.request) {
            /*
            * The request was made but no response was received, `error.request`
            * is an instance of XMLHttpRequest in the browser and an instance
            * of http.ClientRequest in Node.js
            */
            console.log('The request was made but no response was received')
            store.commit('AUTH_LOGOUT');
            router.push({ name: 'Login' });
            // store.dispatch('logout').then(() => {
            //     router.push({ name: 'Login' })
            // })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        return Promise.reject(error);
    }
);

export default axios;