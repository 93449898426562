<template>
  <div class="training">
    <h4>Utbildning</h4>
    <b-row>
      <b-col cols="5">
        <b-form>
          <b-form-group label="Kurs">
            <b-form-select v-model="course" :class="{ 'is-invalid': $v.course.$error }">
              <template #first>
                <b-form-select-option :value="null" disabled
                  >Välj kurs</b-form-select-option
                >
              </template>
              <template v-if="courses.length > 0">
                <b-form-select-option
                  :value="course"
                  v-for="(course, courseKey) in courses"
                  :key="courseKey"
                >
                  {{ course.education }}
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group label="Kursdatum">
            <b-form-datepicker
              start-weekday="1"
              v-model="startDate"
              placeholder="Fr o m"
              style="float: left"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              :class="{ 'is-invalid': $v.startDate.$error }"
            ></b-form-datepicker>
          </b-form-group>
          <b-button variant="success" @click="addPersonEducation()">Spara</b-button>
        </b-form>
      </b-col>
      <b-col cols="7">
        <b-table
          v-if="educations.length > 0"
          striped
          hover
          :items="educations"
          :fields="educationFields"
        >
        </b-table>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col cols="5">
        <h4>Kompetenser</h4>
        <b-form>
          <b-form-group label="Kompetens">
            <b-form-select v-model="qualification" :class="{ 'is-invalid': $v.qualification.$error }">
              <template #first>
                <b-form-select-option :value="null" disabled
                  >Välj Kompetens</b-form-select-option
                >
              </template>
              <template v-if="qualifications.length > 0">
                <b-form-select-option
                  :value="qualification "
                  v-for="(qualification, qKey) in qualifications"
                  :key="qKey"
                >
                  {{ qualification.qualification }}
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group label="Kompetensdatum">
            <b-form-datepicker
              start-weekday="1"
              v-model="qStartDate"
              placeholder="F o m"
              style="float: left"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              :class="{ 'is-invalid': $v.qStartDate.$error }"
            ></b-form-datepicker>
          </b-form-group>
          <b-button variant="success" @click="addPersonQualification()">Spara</b-button>
        </b-form>
      </b-col>
      <b-col cols="7">
        <b-table
          v-if="personQualifications.length > 0"
          striped
          hover
          :items="personQualifications"
          :fields="qualificationFields"
        >
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
export default {
  name: "training",
  props: ["personID"],
  data() {
    return {
      startDate: null,
      course: null,
      courses: [],
      submitStatus: null,
      educations: [],
      educationFields: [
        {key: 'education', label: 'Utbildning'},
        {key: 'startDate', label: 'Kursdatum'}
      ],
      qualifications: [],
      qualification: null,
      qStartDate: null,
      personQualifications: [],
      qualificationFields: [
        {key: 'qualification', label: 'Kompetens'},
        {key: 'startDate', label: 'Kompetensdatum'}
      ],
    };
  },

  validations: {
    course: { required },
    startDate: { required },
    qualification: { required },
    qStartDate: { required },
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
  },

  mounted() {
    this.fetchCourses();
    this.fetchPersonEducations();
    this.fetchQualifications();
    this.fetchPersonQualifications();
  },

  methods: {
    fetchCourses() {
      this.$http.get(this.baseUrl+'api/get.php?type=educations&key='+this.apiKey)
      .then((response) => {
        if (response.data.status == 'ok') {
          this.courses = response.data.data
        }
      })
      .catch((error) => {
        console.log(error)
      })
    },

    addPersonEducation() {
      this.$v.course.$touch();
      this.$v.startDate.$touch();
      if (this.$v.course.$invalid || this.$v.startDate.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";

        var formData = new FormData();
        formData.append('personID', this.personID);
        formData.append('educationID', this.course.educationID);
        formData.append('startDate', this.startDate);

        this.$http.post(this.baseUrl+'api/edit.php?type=addPersonEducation&key='+this.apiKey, formData)
        .then((response) => {
          if (response.data.status == 'ok') {
            this.submitStatus = "OK";
            this.course = null;
            this.startDate = null;
            this.fetchPersonEducations();
            this.$v.course.$reset();
            this.$v.startDate.$reset();
            this.$notify({
              group: "alerts",
              title: "Meddelande",
              text: "Utbildning tillagd till personen",
              type: "success",
            });
          }
        })
        .catch((error) => {
          console.log(error)
        })
      }
    },

    fetchPersonEducations() {
      this.$http.get(this.baseUrl+'api/get.php?type=personEducations&personID='+this.personID+'&key='+this.apiKey)
      .then((response) => {
        if (response.data.status == 'ok') {
          this.educations = response.data.data
        }
      })
      .catch((error) => {
        console.log(error)
      })
    },

    // api/get.php?type=qualifications
    fetchQualifications() {
      this.$http.get(this.baseUrl+'api/get.php?type=qualifications&key='+this.apiKey)
      .then((response) => {
        if (response.data.status == 'ok') {
          this.qualifications = response.data.data
        }
      })
      .catch((error) => {
        console.log(error)
      })
    },

    addPersonQualification() {
      this.$v.qualification.$touch();
      this.$v.qStartDate.$touch();
      if (this.$v.qualification.$invalid || this.$v.qStartDate.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";

        var formData = new FormData();
        formData.append('personID', this.personID);
        formData.append('qualificationID', this.qualification.qualificationID);
        formData.append('startDate', this.qStartDate);

        this.$http.post(this.baseUrl+'api/edit.php?type=addPersonQualification&key='+this.apiKey, formData)
        .then((response) => {
          if (response.data.status == 'ok') {
            this.submitStatus = "OK";
            this.qualification = null;
            this.qStartDate = null;
            this.fetchPersonQualifications();
            this.$v.qualification.$reset();
            this.$v.qStartDate.$reset();
            this.$notify({
              group: "alerts",
              title: "Meddelande",
              text: "Kompetens tillagd till personen",
              type: "success",
            });
          }
        })
      }
    },

    fetchPersonQualifications() {
      this.$http.get(this.baseUrl+'api/get.php?type=personQualifications&personID='+this.personID+'&key='+this.apiKey)
      .then((response) => {
        if (response.data.status == 'ok') {
          this.personQualifications = response.data.data
        }
      })
      .catch((error) => {
        console.log(error)
      })
    }
  }
};
</script>
