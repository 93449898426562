<template>
  <div class="ki">
    <b-row class="bbox">
      <b-col>
        <h3>Förarbevis</h3>
        <b-form>
          <b-form-group label="Förarbevisnummer" class="form-inline">
            <b-form-select v-model="fbCountry" class="mr-2">
              <b-form-select-option value="SE" se>SE</b-form-select-option>
            </b-form-select>
            <b-form-input
              type="number"
              v-model="fbNumber"
              placeholder="Nummer"
              :class="{ 'is-invalid': $v.fbNumber.$error }"
              :disabled="driverLicense != null"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Giltighetstid" class="form-inline">
            <b-form-datepicker
              class="mr-2"
              start-weekday="1"
              v-model="fbStartDate"
              placeholder="Fr o m"
              style="float: left"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              :class="{ 'is-invalid': $v.fbStartDate.$error }"
              :disabled="driverLicense != null"
            ></b-form-datepicker>
            <b-form-datepicker
              start-weekday="1"
              v-model="fbEndDate"
              placeholder="T o m"
              style="float: left"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              :class="{ 'is-invalid': $v.fbEndDate.$error }"
              :disabled="driverLicense != null"
            ></b-form-datepicker>
          </b-form-group>
          <b-button
            variant="success"
            @click="saveDriverLicense()"
            :disabled="driverLicense != null"
            >Spara</b-button
          >
        </b-form>
      </b-col>
      <b-col>
        <h3>Åtgärder</h3>
        <ul>
          <li>Nytt förarbevis</li>
          <li>Ändra status</li>
        </ul>
      </b-col>
    </b-row>
    <b-row class="bbox">
      <b-col>
        <h3>Kompletterande intyg</h3>
        <b-row>
          <b-col>
            <b-form>
              <b-form-group class="form-inline" label="Infrastruktur">
                <b-form-input
                  type="text"
                  v-model="infrastructure"
                  placeholder="Infrastruktur"
                  class="mr-2"
                  :class="{ 'is-invalid': $v.infrastructure.$error }"
                ></b-form-input>
                <b-form-input
                  type="text"
                  v-model="infraNotes"
                  placeholder="Anmärkning"
                ></b-form-input>
                <b-datepicker
                  class="mt-2"
                  start-weekday="1"
                  v-model="infraDate"
                  placeholder="Fr o m"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  :class="{ 'is-invalid': $v.infraDate.$error }"
                ></b-datepicker>
                <b-button
                  variant="success"
                  class="mt-2"
                  @click="saveSupplementaryCertificate()"
                  >Spara</b-button
                >
              </b-form-group>
            </b-form>
          </b-col>
          <b-col>
            <b-table
              v-if="supplementaryCertificates.length > 0"
              striped
              hover
              :items="supplementaryCertificates"
              :fields="scFields"
            >
              <template #cell(note)="data">
                {{ data.value == "null" ? "-" : data.value }}
              </template>
            </b-table>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-form>
              <b-form-group class="form-inline" label="Språk">
                <b-form-input
                  class="mr-2"
                  type="text"
                  v-model="language"
                  placeholder="Språk"
                  :class="{ 'is-invalid': $v.language.$error }"
                ></b-form-input>
                <b-form-input
                  type="text"
                  v-model="langNotes"
                  placeholder="Anmärkning"
                ></b-form-input>
                <b-datepicker
                  class="mt-2"
                  start-weekday="1"
                  v-model="langDate"
                  placeholder="Fr o m"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  :class="{ 'is-invalid': $v.langDate.$error }"
                ></b-datepicker>
                <b-button variant="success" class="mt-2" @click="saveLanguage()"
                  >Spara</b-button
                >
              </b-form-group>
            </b-form>
          </b-col>
          <b-col>
            <b-table
              v-if="languages.length > 0"
              striped
              hover
              :items="languages"
              :fields="languageFields"
            >
              <template #cell(note)="data">
                {{ data.value == "null" ? "-" : data.value }}
              </template>
            </b-table>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-form>
              <b-form-group class="form-inline" label="Fordon">
                <b-form-select
                  @change="vehicleChanged"
                  v-model="vehicle"
                  class="mr-2"
                  :class="{ 'is-invalid': $v.vehicle.$error }"
                >
                  <b-form-select-option :value="null"
                    >Välj fordonstyp</b-form-select-option
                  >
                  <template v-if="personVehicles.length > 0">
                    <b-form-select-option
                      :value="vehicle"
                      v-for="(vehicle, vehicleKey) in personVehicles"
                      :key="vehicleKey"
                      :disabled="vehicle.disabled"
                    >
                      {{ vehicle.vehicle }} ({{ vehicle.note }}) -
                      {{ vehicle.startDate }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-input
                  type="text"
                  v-model="vehicleNotes"
                  placeholder="Anmärkning"
                ></b-form-input>
                <b-datepicker
                  class="mt-2"
                  start-weekday="1"
                  v-model="vehicleStartDate"
                  placeholder="Fr o m"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  :class="{ 'is-invalid': $v.vehicleStartDate.$error }"
                ></b-datepicker>
                <b-button variant="success" class="mt-2" @click="saveVehicle()"
                  >Spara</b-button
                >
              </b-form-group>
            </b-form>
          </b-col>
          <b-col>
            <b-table
              v-if="driverVehicles.length > 0"
              striped
              hover
              :items="driverVehicles"
              :fields="driverVehiclesFields"
            ></b-table>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-form>
              <b-form-group label="Extra personuppgifter" class="form-inline">
                <b-form-input
                  class="mr-2"
                  v-model="kiCity"
                  placeholder="Födelseort"
                  :class="{ 'is-invalid': $v.kiCity.$error }"
                ></b-form-input>
                <b-form-input
                  v-model="kiNationality"
                  placeholder="Nationalitet"
                  :class="{ 'is-invalid': $v.kiNationality.$error }"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Förarkategorier">
                <b-form-checkbox-group
                  v-model="driverA"
                  :options="catA"
                  value-field="text"
                  text-field="value"
                ></b-form-checkbox-group>
                <b-form-checkbox-group
                  v-model="driverB"
                  :options="catB"
                  value-field="text"
                  text-field="value"
                ></b-form-checkbox-group>
              </b-form-group>
              <b-button variant="success" @click="saveExtraPersonalInfo()"
                >Spara</b-button
              >
            </b-form>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <h3>Åtgärder</h3>
            <ul>
              <li>Kontrollera behörigheter</li>
              <li>Återkalla KI</li>
              <li>Utfärda KI</li>
              <li>Skriv ut kopia</li>
            </ul>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "ki",
  props: ["personID"],
  data() {
    return {
      fbCountry: "SE",
      fbNumber: null,
      fbStartDate: null,
      fbEndDate: null,
      kiCity: null,
      kiNationality: "svensk",
      infraDate: null,
      infrastructure: null,
      infraNotes: null,
      langDate: null,
      language: null,
      langNotes: null,
      driverA: [],
      driverB: [],
      catA: [
        { text: "A1", value: "A1" },
        { text: "A2", value: "A2" },
        { text: "A3", value: "A3" },
        { text: "A4", value: "A4" },
        { text: "A5", value: "A5" },
        { text: "A6", value: "A6" },
      ],
      catB: [
        { text: "B1", value: "B1" },
        { text: "B2", value: "B2" },
      ],
      submitStatus: null,
      driverLicense: null,
      vehicleNotes: null,
      vehicleStartDate: null,
      vehicle: null,
      supplementaryCertificates: [],
      languages: [],
      scFields: [
        { key: "roleValue", label: "Infrastruktur" },
        { key: "note", label: "Anmärkning" },
        { key: "startDate", label: "Starttid" },
      ],
      languageFields: [
        { key: "roleValue", label: "Språk" },
        { key: "note", label: "Anmärkning" },
        { key: "startDate", label: "Starttid" },
      ],
      driverVehiclesFields: [
        { key: "roleValue", label: "Fordon" },
        { key: "note", label: "Anmärkning" },
        { key: "startDate", label: "Starttid" },
      ],
      extrInfoFields: [
        { key: "birthPlace", label: "Födelseort" },
        { key: "nationality", label: "Nationalitet" },
        { key: "driverCategory", label: "Förarkategorier" },
      ],
    };
  },

  validations: {
    fbCountry: { required },
    fbNumber: { required },
    fbStartDate: { required },
    fbEndDate: { required },
    infrastructure: { required },
    infraDate: { required },
    language: { required },
    langDate: { required },
    kiCity: { required },
    kiNationality: { required },
    vehicle: { required },
    vehicleStartDate: { required },
  },

  computed: {
    ...mapState(["baseUrl", "apiKey", "personVehicles", "driverVehicles"]),
  },

  watch: {
    driverVehicles: function () {
      this.$store.commit("CHECK_ENABLED");
    },
  },

  mounted() {
    this.fetchDriverLicense();
    this.fetchSupplementaryCertificate();
    this.fetchLanguage();
    this.fetchExtraPersonalInfo();

    this.$store.dispatch("fetchPersonVehicles", this.personID);
    this.$store.dispatch("fetchDriverVehicles", this.personID);
  },

  methods: {
    fetchDriverLicense() {
      if (this.personID != null) {
        this.$http
          .get(
            this.baseUrl +
              "api/get.php?type=fb&personID=" +
              this.personID +
              "&key=" +
              this.apiKey
          )
          .then((response) => {
            if (response.data.status == "ok" && response.data.data.length > 0) {
              const data = response.data.data[0];
              this.driverLicense = data;

              this.fbCountry = data.fbnr.substring(0, 2);
              this.fbNumber = data.fbnr.substring(2);
              this.fbStartDate = data.startDate;
              this.fbEndDate = data.endDate;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    fetchSupplementaryCertificate() {
      if (this.personID != null) {
        this.$http
          .get(
            this.baseUrl +
              "api/get.php?type=infra&personID=" +
              this.personID +
              "&key=" +
              this.apiKey
          )
          .then((response) => {
            if (response.data.status == "ok") {
              this.supplementaryCertificates = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    fetchLanguage() {
      if (this.personID != null) {
        this.$http
          .get(
            this.baseUrl +
              "api/get.php?type=lang&personID=" +
              this.personID +
              "&key=" +
              this.apiKey
          )
          .then((response) => {
            if (response.data.status == "ok") {
              this.languages = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    fetchExtraPersonalInfo() {
      if (this.personID != null) {
        this.$http
          .get(
            this.baseUrl +
              "api/get.php?type=extra&personID=" +
              this.personID +
              "&key=" +
              this.apiKey
          )
          .then((response) => {
            if (response.data.status == "ok") {
              // this.extraPersonInfos = response.data.data;
              const data = response.data.data[0];
              this.kiCity = data.birthPlace;
              this.kiNationality =
                data.length > 0 && data.nationality != null
                  ? data.nationality
                  : "svensk";

              const categories = data.driverCategory.split(",");
              categories.map((cat) => {
                if (cat.substring(0, 1) == "A") {
                  this.driverA.push(cat);
                } else if (cat.substring(0, 1) == "B") {
                  this.driverB.push(cat);
                }
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    saveDriverLicense() {
      this.$v.fbCountry.$touch();
      this.$v.fbNumber.$touch();
      this.$v.fbStartDate.$touch();
      this.$v.fbEndDate.$touch();

      if (
        this.$v.fbCountry.$invalid ||
        this.$v.fbNumber.$invalid ||
        this.$v.fbStartDate.$invalid ||
        this.$v.fbEndDate.$invalid
      ) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";
        const fbnr = this.fbCountry + this.fbNumber;

        var formData = new FormData();
        formData.append("fbnr", fbnr);
        formData.append("startDate", this.fbStartDate);
        formData.append("endDate", this.fbEndDate);
        formData.append("personID", this.personID);

        this.$http
          .post(
            this.baseUrl + "api/edit.php?type=saveFb&key=" + this.apiKey,
            formData
          )
          .then((response) => {
            if (response.data.status == "ok") {
              this.$notify({
                group: "alerts",
                title: "Meddelande",
                text: "Data har sparats!",
                type: "success",
              });
              this.fetchDriverLicense();
              this.$v.$reset();
            }
          })
          .catch((error) => {
            console.log(error);
            this.$notify({
              group: "alerts",
              title: "Meddelande",
              text: "Det gick inte att spara!",
              type: "error",
            });
          });
      }
    },

    saveSupplementaryCertificate() {
      this.$v.infrastructure.$touch();
      this.$v.infraDate.$touch();

      if (this.$v.infrastructure.$invalid || this.$v.infraDate.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";
        var formData = new FormData();
        formData.append("personID", this.personID);
        formData.append("roleValue", this.infrastructure);
        formData.append("note", this.infraNotes);
        formData.append("startDate", this.infraDate);

        this.$http
          .post(
            this.baseUrl + "api/edit.php?type=addInfra&key=" + this.apiKey,
            formData
          )
          .then((response) => {
            if (response.data.status == "ok") {
              this.infrastructure = null;
              this.infraNotes = null;
              this.infraDate = null;
              this.$notify({
                group: "alerts",
                title: "Meddelande",
                text: "Data har sparats!",
                type: "success",
              });
              this.fetchSupplementaryCertificate();
              this.$v.$reset();
            }
          })
          .catch((error) => {
            console.log(error);
            this.$notify({
              group: "alerts",
              title: "Meddelande",
              text: "Det gick inte att spara!",
              type: "error",
            });
          });
      }
    },

    saveLanguage() {
      this.$v.language.$touch();
      this.$v.langDate.$touch();

      if (this.$v.language.$invalid || this.$v.langDate.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";
        var formData = new FormData();
        formData.append("personID", this.personID);
        formData.append("roleValue", this.language);
        formData.append("note", this.langNotes);
        formData.append("startDate", this.langDate);

        this.$http
          .post(
            this.baseUrl + "api/edit.php?type=addLang&key=" + this.apiKey,
            formData
          )
          .then((response) => {
            if (response.data.status == "ok") {
              this.language = null;
              this.langNotes = null;
              this.langDate = null;
              this.$notify({
                group: "alerts",
                title: "Meddelande",
                text: "Data har sparats!",
                type: "success",
              });
              this.fetchLanguage();
              this.$v.$reset();
            }
          })
          .catch((error) => {
            console.log(error);
            this.$notify({
              group: "alerts",
              title: "Meddelande",
              text: "Det gick inte att spara!",
              type: "error",
            });
          });
      }
    },

    saveExtraPersonalInfo() {
      this.$v.kiCity.$touch();
      this.$v.kiNationality.$touch();
      if (this.$v.kiCity.$invalid || this.$v.kiNationality.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";

        const driverCategory = this.driverA.concat(this.driverB);

        var formData = new FormData();
        formData.append("personID", this.personID);
        formData.append("birthPlace", this.kiCity);
        formData.append("nationality", this.kiNationality);
        formData.append("driverCategory", driverCategory.join());

        this.$http
          .post(
            this.baseUrl + "api/edit.php?type=addExtra&key=" + this.apiKey,
            formData
          )
          .then((response) => {
            if (response.data.status == "ok") {
              this.$notify({
                group: "alerts",
                title: "Meddelande",
                text: "Data har sparats!",
                type: "success",
              });
              this.kiCity = null;
              this.kiNationality = "svensk";
              this.driverA = [];
              this.driverB = [];
              this.fetchExtraPersonalInfo();
              this.$v.$reset();
            }
          })
          .catch((error) => {
            console.log(error);
            this.$notify({
              group: "alerts",
              title: "Meddelande",
              text: "Det gick inte att spara!",
              type: "error",
            });
          });
      }
    },

    saveVehicle() {
      this.$v.vehicle.$touch();
      this.$v.vehicleStartDate.$touch();

      if (this.$v.vehicle.$invalid || this.$v.vehicleStartDate.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";

        var formData = new FormData();
        formData.append("roleValue", this.vehicle.vehicle);
        if (this.vehicleNotes != null && this.vehicleNotes.length > 0) {
          formData.append("note", this.vehicleNotes);
        }
        formData.append("startDate", this.vehicleStartDate);
        formData.append("personID", this.personID);

        this.$http
          .post(
            this.baseUrl + "api/edit.php?type=addVehicle&key=" + this.apiKey,
            formData
          )
          .then((response) => {
            console.log(response.data.data);
            if (response.data.status == "ok") {
              this.vehicle = null;
              this.vehicleNotes = null;
              this.vehicleStartDate = null;
              this.$notify({
                group: "alerts",
                title: "Meddelande",
                text: "Ett fordon inlagt",
                type: "success",
              });
              this.$store.dispatch("fetchDriverVehicles", this.personID);
              this.$v.$reset();
            }
          })
          .catch((error) => {
            console.log(error);
            this.$notify({
              group: "alerts",
              title: "Meddelande",
              text: "Det gick inte att spara!",
              type: "error",
            });
          });
      }
    },

    vehicleChanged(vehicle) {
      this.vehicleNotes = vehicle.note;
      this.vehicleStartDate = vehicle.startDate;
    },
  },
};
</script>

<style lang="scss">
.bbox {
  border-color: #cecece;
  border-width: 1px;
  border-style: solid;
  padding: 8px;
  margin: 8px;
}
</style>