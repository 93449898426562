<template>
    <div class="main w-100">
        <navbar :title="'Intyg'" />
        <div class="certificate p-4">
            <div class="card mb-5">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <h4>&nbsp;</h4>
                        </div>
                        <div class="col-md-6 text-right">
                            <button
                                type="button"
                                class="btn btn-success"
                                @click="print()"
                            >
                                <font-awesome-icon icon="print" /> Skriv ut
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="printable">
                <div class="row mb-5 header">
                    <div class="col-md-3 left text-center">
                        <p class="font-weight-bolder">JF/IF</p>
                        <p class="font-weight-bolder">Emblem</p>
                    </div>
                    <div class="col-md-9 right">
                        <div class="content text-center">
                            <p class="main-title font-weight-bold">KOPIA AV KOMPLETTERANDE INTYG</p>
                            <p class="subtitle">Utfärdat i enlighet med artikel 17 i direktiv 2007/59/EG. detta är inget förarbevis</p>
                        </div>
                        <div class="logo">
                            <img :src="require('@/assets/eu.png')" alt="eu" width="150px">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <h4>1. ARBETSGIVARE -UPPDRAGSGIVARE JF/IF</h4>
                    </div>
                    <div class="col-md-9">
                        <textarea class="form-control" rows="5" placeholder="FÖRETAGSNAMN"></textarea>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-3">
                        <table class="table table-borderless">
                            <tbody>
                                <tr>
                                    <td>Postadress</td>
                                </tr>
                                <tr>
                                    <td>Postnummer och ort</td>
                                </tr>
                                <tr>
                                    <td>Innehavarens anställningsnummer</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-9">
                        <table class="table table-bordered">
                            <tbody>
                                <tr>
                                    <td colspan="3" style="height: 50px;">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td style="width:33%;"></td>
                                    <td style="width:33%;">Land</td>
                                    <td style="width:33%;"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="height: 50px;">&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-3">
                        <h4>3. KATEGORI</h4>
                    </div>
                    <div class="col-md-9">
                        <textarea class="form-control" rows="5"></textarea>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-3">
                        <h4>4. KOMPLETTERANDE INFORMATION</h4>
                    </div>
                    <div class="col-md-9">
                        <textarea class="form-control" rows="5"></textarea>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-3">
                        <h4>5. BEGRÄNSNINGAR</h4>
                    </div>
                    <div class="col-md-9">
                        <textarea class="form-control" rows="5"></textarea>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-3">
                        <h4>6. SPRÅKKUNSKAPER</h4>
                    </div>
                    <div class="col-md-9">
                        <textarea class="form-control" rows="5"></textarea>
                    </div>
                </div>
                <div class="row mt-4 material">
                    <div class="col-md-3">
                        <h4>7. RULLANDE MATERIEL</h4>
                    </div>
                    <div class="col-md-9">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col" style="text-align: center;">Beskrivning</th>
                                    <th scope="col" style="text-align: center;">Anmärkning</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row mt-4 material">
                    <div class="col-md-3">
                        <h4>8. INFRASTRUKTUR</h4>
                    </div>
                    <div class="col-md-9">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col" style="text-align: center;">Beskrivning</th>
                                    <th scope="col" style="text-align: center;">Anmärkning</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-3">
                        <h4>SLUTDATUM FÖR FÖRARENS VERKSAMHET FÖR JF/IF</h4>
                    </div>
                    <div class="col-md-9">
                        <textarea class="form-control" rows="5"></textarea>
                    </div>
                </div>
                <div class="row mt-4 material">
                    <div class="col-md-3">
                        <table class="table table-borderless header">
                            <tbody>
                                <tr>
                                    <td style="height: 50px;">UTFÄRDANDE ORGAN</td>
                                </tr>
                                <tr>
                                    <td>Postadress</td>
                                </tr>
                                <tr>
                                    <td>Tfn</td>
                                </tr>
                                <tr>
                                    <td>E-post</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-9">
                        <table class="table table-bordered">
                            <tbody>
                                <tr>
                                    <td colspan="2" style="height: 50px;">&nbsp;</td>
                                    <td style="height: 50px;">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td colspan="2">&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td style="width:33%;"></td>
                                    <td style="width:33%;">Fax</td>
                                    <td style="width:33%;"></td>
                                </tr>
                                <tr>
                                    <td colspan="2">&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td colspan="2">&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col">
                        <div class="signature mt-5">
                            <h6>Datum för utfärdande</h6>
                            <input type="text" class="form-control form-control-lg">
                        </div>
                        <div class="signature mt-5">
                            <h6>Internt företagsnummer</h6>
                            <input type="text" class="form-control form-control-lg">
                        </div>
                    </div>
                    <div class="col">
                        <div class="box mt-5">
                            <h6>Namnteckning</h6>
                            <div class="empty-line"></div>
                        </div>
                        <div class="box mt-2">
                            <h6>&nbsp;</h6>
                            <div class="empty-box"></div>
                        </div>
                    </div>
                </div>
                <div class="empty"></div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
export default {
    name: 'Certificate',
    data() {
        return {
            output: null
        }
    },

    components: {
        Navbar
    },

    methods: {
        print() {
            var printContents = document.getElementById('printable').innerHTML;
            var originalContents = document.body.innerHTML;

            document.body.innerHTML = printContents;

            window.print();

            document.body.innerHTML = originalContents;
        }
    }
}
</script>

<style lang="scss" scoped>
.certificate {
    background: #fff;
    margin: 15px;

    h4 {
        font-size: 20px;
        font-weight: 600;
    }

    .card {
        max-width: 1100px;
        margin: 0 auto;
        button {
            font-weight: 600;
        }
    }

    #printable {
        border: 1px solid #ddd;
        padding: 25px;
        max-width: 1100px;
        margin: 0 auto;
    }

    .header {
        .left {
            p {
                font-weight: 800;
                font-size: 20px;
            }
        }
        .right {
            display: flex;
            flex-direction: row;
            .content {
                padding: 0 80px;
                border-bottom: 1px solid #000;
                p {
                    margin: 0;
                }
                .main-title {
                    font-size: 20px;
                }
                .subtitle {
                    font-size: 18px;
                }
            }
            .logo {
                margin-left: 20px;
            }
        }
    }

    .material {
        .table {
            thead th {
                border-bottom: 0;
                font-size: 17px;
            }
            th {
                height: 50px;
            }
            td {
                height: 35px;
                padding: 0.5rem 0.75rem;
            }
        }
        .header {
            tr td {
                padding: 0.5rem;
            }
        }
    }

    .signature {
        text-align: center;
        max-width: 30rem;
    }

    .empty {
        margin-bottom: 20rem;
    }

    .box {
        display: flex;
        flex-direction: row;
        h6 {
            width: 150px;
        }
        .empty-line {
            // height: 5px;
            width: 100%;
            border-bottom: 1px solid #ced4da;
        }
        .empty-box {
            margin-top: 20px;
            width: 100%;
            height: 150px;
            border: 1px solid #ced4da;
        }
    }
}
</style>
