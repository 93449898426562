<template>
  <div id="app">
    <notifications group="alerts" />
    <sidebar />
    <router-view />
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
export default {
  components: {
    Sidebar,
  },
};
</script>

<style lang="scss">
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/bootstrap-vue/src/index.scss";
// @import 'sweetalert2/dist/sweetalert2.min.css';
html,
body {
  height: 100vh;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  display: flex;
  width: 100%;
  align-items: stretch;
  height: 100%;

  .vue-notification-group {
    margin-top: 15px;
  }

  .main-content {
    background: #f9fbfd;
  }

  .main {
    background: #f8f9f9;
  }

  .errors {
    p {
      margin: 0;
      font-size: 15px;
    }
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
