<template>
  <div class="main w-100">
    <navbar :title="'Person'" />
    <div class="competences p-3">
      <div>
        <b-card no-body>
          <b-tabs pills card>
            <b-tab title="Personuppgifter" active>
              <template v-slot:title>
                <font-awesome-icon icon="umbrella" /> Personuppgifter
              </template>
              <b-card-text>
                <personaldetails
                  @person="person"
                  :personID="personID"
                  v-if="hasMounted"
                />
              </b-card-text>
            </b-tab>
            <b-tab title="Fordon" :disabled="!isDisabled">
              <template v-slot:title>
                <font-awesome-icon icon="tags" /> Fordon
              </template>
              <b-card-text>
                <vehicles :personID="personID" v-if="hasMounted" />
              </b-card-text>
            </b-tab>
            <b-tab title="KI" :disabled="!isDisabled">
              <template v-slot:title>
                <font-awesome-icon icon="sliders-h" /> Förarbevis/KI
              </template>
              <b-card-text>
                <ki :personID="personID" v-if="hasMounted" />
              </b-card-text>
            </b-tab>
            <b-tab title="Halsa" :disabled="!isDisabled">
              <template v-slot:title>
                <font-awesome-icon icon="file-signature" /> Hälsa
              </template>
              <b-card-text>
                <health :personID="personID" v-if="hasMounted" />
              </b-card-text>
            </b-tab>
            <b-tab title="Utbildning" :disabled="!isDisabled">
              <template v-slot:title>
                <font-awesome-icon icon="donate" /> Utbildning
              </template>
              <b-card-text>
                <training :personID="personID" v-if="hasMounted" />
              </b-card-text>
            </b-tab>
            <b-tab title="Dela" :disabled="!isDisabled">
              <template v-slot:title>
                <font-awesome-icon icon="map-signs" /> Dela
              </template>
              <b-card-text>
                <rental :personID="personID" v-if="hasMounted" />
              </b-card-text>
            </b-tab>
            <b-tab title="Dokument" :disabled="!isDisabled">
              <template v-slot:title>
                <font-awesome-icon icon="map-signs" /> Dokument
              </template>
              <b-card-text>
                <documents :personID="personID" v-if="hasMounted" />
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Personaldetails from "@/views/persons/PersonalDetails.vue";
import Health from "@/views/persons/Health.vue";
import Ki from "@/views/persons/Ki.vue";
import Rental from "@/views/persons/Rental.vue";
import Training from "@/views/persons/Training.vue";
import Vehicles from "@/views/persons/Vehicles.vue";
import { mapState } from 'vuex';
import Documents from './Documents.vue';

// window.onpopstate = function () {
//   location.reload();
// };

export default {
  name: "Person",
  data() {
    return {
      hasMounted: false,
      personID: null
    };
  },

  computed: {
    ...mapState(['baseUrl', 'apiKey', 'personVehicles']),

    isDisabled() {
      return !!this.personID;
    },
  },

  components: {
    Navbar,
    Personaldetails,
    Health,
    Ki,
    Rental,
    Training,
    Vehicles,
    Documents,
  },

  mounted() {
    this.hasMounted = true;
    const params = this.$route.params;
    if (params.personID != undefined) {
      console.log('Person id found!!')
      this.personID = params.personID;
    } else {
      this.personID = null;
    }
  },

  methods: {
    person(event) {
      this.personID = event;
    },
  },
};
</script>