<template>
  <div class="rental">
    <b-container>
      <h4>Dela anställd</h4>
      <b-row>
        <b-col cols="5">
          <b-form>
            <b-form-group label="Slutdatum">
              <b-form-datepicker
                start-weekday="1"
                v-model="endDate"
                placeholder="T o m"
                style="float: left"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              ></b-form-datepicker>
            </b-form-group>
            <b-form-group label="Dela med">
              <b-form-input
                placeholder="E-postadress"
                v-model="share"
              ></b-form-input>
            </b-form-group>
            <b-button variant="success" @click="addShare()">Spara</b-button>
          </b-form>
        </b-col>
        <b-col cols="7">
          <b-table
            v-if="shares.length > 0"
            striped
            hover
            :items="shares"
            :fields="shareFields"
          >
          </b-table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "rental",
  props: ["personID"],
  data() {
    return {
      endDate: null,
      share: null,
      shares: [],
      shareFields: [
        { key: "email", label: "Delad med" },
        { key: "expireDate", label: "Till och med" },
      ],
    };
  },
  computed: {
    ...mapState(["baseUrl", "apiKey"]),
  },

  mounted() {
    this.fetchShares();
  },
  methods: {
    fetchShares() {
      this.$http
        .get(
          this.baseUrl +
            "api/get.php?type=shares&personID= " +
            this.personID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.shares = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    addShare() {
      var formData = new FormData();
      formData.append("personID", this.personID);
      formData.append("share", this.share);
      formData.append("expireDate", this.endDate);

      this.$http
        .post(
          this.baseUrl + "api/edit.php?type=addShare&key=" + this.apiKey,
          formData
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.share = null;
            this.endDate = null;
            this.fetchShares();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
</style>