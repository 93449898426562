<template>
  <div class="main w-100">
    <navbar :title="'Personal'" />
    <div class="staffs p-3">
      <div class="card mb-3">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <h4>&nbsp;</h4>
            </div>
            <div class="col-md-6 text-right">
              <button
                type="button"
                class="btn btn-success"
                @click="showModal = true"
              >
                <font-awesome-icon icon="plus-circle" /> Ny person
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="persons-list">
        <table class="table table-hover" v-if="persons.length > 0">
          <thead>
            <tr>
              <th scope="col">Namn</th>
              <th scope="col">Anställningsnr</th>
              <th scope="col">Adress</th>
              <th scope="col">Ort</th>
              <th scope="col">Mobiltelefon</th>
              <th scope="col">Privat telefon</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(person, personKey) in persons" :key="personKey">
              <td class="title">
                <span class="status">
                  <span
                    :title="person.reason"
                    :class="{
                      red: getMonths(person.expireDate) <= 0,
                      orange:
                        getMonths(person.expireDate) > 0 &&
                        getMonths(person.expireDate) <= 1,
                      yellow:
                        getMonths(person.expireDate) > 1 &&
                        getMonths(person.expireDate) <= 4,
                      green: getMonths(person.expireDate) > 4,
                    }"
                    >&nbsp;</span
                  >&nbsp;
                  <router-link
                    :to="{
                      name: 'Personaldetails',
                      params: { personID: person.personID },
                    }"
                    >{{ person.firstName }} {{ person.lastName }}
                  </router-link></span
                >
              </td>
              <td>{{ person.employeeNr }}</td>
              <td>
                {{ person.address }}, {{ person.postcode }} {{ person.city }}
              </td>
              <td>{{ person.city }}</td>
              <td>{{ person.mobilePhone }}</td>
              <td>
                {{ person.privatePhone != "null" ? person.privatePhone : "-" }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="alert alert-primary" v-else>Ingen personal inlagd.</div>
      </div>
    </div>
    <template>
      <b-modal
        id="modal-1"
        size="lg"
        :title="'Lägg till personal'"
        :hide-footer="true"
        v-model="showModal"
        @hide="reset()"
      >
        <div class="form-group">
          <div class="row">
            <div class="col">
              <input
                type="text"
                v-model="firstName"
                class="form-control form-control-lg"
                placeholder="Namn"
                :class="{ 'is-invalid': $v.firstName.$error }"
              />
              <div class="invalid-feedback" v-if="!$v.firstName.required">
                Ange namn
              </div>
            </div>
            <div class="col">
              <input
                type="text"
                v-model="lastName"
                class="form-control form-control-lg"
                placeholder="Efternamn"
                :class="{ 'is-invalid': $v.lastName.$error }"
              />
              <div class="invalid-feedback" v-if="!$v.lastName.required">
                Ange Efternamn
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <input
            type="number"
            class="form-control form-control-lg"
            placeholder="ÅÅÅÅMMDDNNNN"
            v-model="pnr"
            :class="{ 'is-invalid': $v.pnr.$error }"
          />
          <div class="invalid-feedback" v-if="!$v.pnr.required">
            Ange ditt personnummer.
          </div>
        </div>
        <div class="form-group">
          <input
            type="number"
            class="form-control form-control-lg"
            placeholder="Anställningsnummer"
            v-model="employeeNumber"
            :class="{ 'is-invalid': $v.employeeNumber.$error }"
          />
          <div class="invalid-feedback" v-if="!$v.employeeNumber.required">
            Ange ditt anställningsnummer.
          </div>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control form-control-lg"
            placeholder="Gatuadress"
            v-model="streetAddress"
            :class="{ 'is-invalid': $v.streetAddress.$error }"
          />
          <div class="invalid-feedback" v-if="!$v.streetAddress.required">
            Ange gatuadress.
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col">
              <input
                type="number"
                v-model="postalNumber"
                class="form-control form-control-lg"
                placeholder="Postnummer"
                :class="{ 'is-invalid': $v.postalNumber.$error }"
              />
              <div class="invalid-feedback" v-if="!$v.postalNumber.required">
                Ange postnummer
              </div>
            </div>
            <div class="col">
              <input
                type="text"
                v-model="city"
                class="form-control form-control-lg"
                placeholder="Ort"
                :class="{ 'is-invalid': $v.city.$error }"
              />
              <div class="invalid-feedback" v-if="!$v.city.required">
                Ange ort
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col">
              <input
                type="text"
                v-model="mobile"
                class="form-control form-control-lg"
                placeholder="Mobiltelefon"
                :class="{ 'is-invalid': $v.mobile.$error }"
              />
              <div class="invalid-feedback" v-if="!$v.mobile.required">
                Ange mobiltelefon
              </div>
            </div>
            <div class="col">
              <input
                type="text"
                v-model="phone"
                class="form-control form-control-lg"
                placeholder="Privat telefon"
              />
            </div>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-success btn-block btn-lg mt-3 mb-4"
          @click="savePerson()"
        >
          Spara
        </button>
      </b-modal>
    </template>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import moment from "moment";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "Staffs",
  props: ["title"],
  data() {
    return {
      hasMounted: false,
      showModal: false,
      persons: [],
      firstName: null,
      lastName: null,
      pnr: null,
      employeeNumber: null,
      streetAddress: null,
      postalNumber: null,
      city: null,
      mobile: null,
      phone: null,
      submitStatus: false,
    };
  },

  validations: {
    firstName: { required },
    lastName: { required },
    pnr: { required },
    employeeNumber: { required },
    streetAddress: { required },
    postalNumber: { required },
    city: { required },
    mobile: { required },
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
  },

  components: {
    Navbar,
  },

  mounted() {
    this.hasMounted = true;
    this.fetchPersons();
  },

  methods: {
    fetchPersons() {
      this.$http
        .get(this.baseUrl + "api/get.php?type=persons&key=" + this.apiKey)
        .then((response) => {
          this.persons = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    savePerson() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";

        var formData = new FormData();
        formData.append("firstName", this.firstName);
        formData.append("lastName", this.lastName);
        formData.append("pnr", this.pnr);
        formData.append("employeeNr", this.employeeNumber);
        formData.append("address", this.streetAddress);
        formData.append("postcode", this.postalNumber);
        formData.append("city", this.city);
        formData.append("mobilePhone", this.mobile);
        formData.append("privatePhone", this.phone);

        this.$http
          .post(
            this.baseUrl + "api/edit.php?type=addPerson&key=" + this.apiKey,
            formData
          )
          .then((response) => {
            if (response.data.status == "ok") {
              this.fetchPersons();

              this.firstName = null;
              this.lastName = null;
              this.pnr = null;
              this.employeeNumber = null;
              this.streetAddress = null;
              this.postalNumber = null;
              this.city = null;
              this.mobile = null;
              this.phone = null;
              this.showModal = false;
              this.$v.$reset();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    getMonths(date) {
      return moment(date).diff(moment(), "months", true);
    },

    reset() {
      this.$v.$reset();
    },
  },
};
</script>
<style lang="scss" scoped>
.staffs {
  .persons-list {
    background: #fff;
  }

  .table {
    tr:hover {
      .title a {
        color: #2e86c1;
      }
    }
    .title {
      a {
        color: #797d7f;
        font-weight: 600;
        &:hover {
          color: #2e86c1;
        }
      }
    }
    .action {
      a {
        color: #2e86c1;
        font-weight: 600;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 1px;
        &:hover {
          color: #797d7f;
        }
      }
    }
  }
}
.status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .red,
  .orange,
  .yellow,
  .green {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .percent {
    font-weight: 600;
    font-size: 14px;
  }
  .green {
    background: #27ae60;
  }
  .yellow {
    background: #f1c40f;
  }
  .orange {
    background: #e67e22;
  }
  .red {
    background: #e74c3c;
  }
}
</style>
