<template>
    <aside class="sidebar" v-if="authenticated && this.$route.name !== 'Login'">
        <div class="header">
            <!-- <h5>Kompetensregister</h5> -->
            <router-link :to="{ name: 'Home' }">
                <img alt="TSAB logo" src="../assets/logo.png" width="120">
            </router-link>
        </div>
        <ul>
            <li>
                <router-link class="menu" :to="{ name: 'Home' }" >Start</router-link>
            </li>
            <li>
                <router-link class="menu" :to="{ name: 'Staffs' }" >Personal</router-link>
            </li>
            <li>
                <router-link class="menu" :to="{ name: 'Educations' }" >Utbildningar</router-link>
            </li>
            <li>
                <router-link class="menu" :to="{ name: 'Qualifications' }" >Kompetenser</router-link>
            </li>
            <li>
                <router-link class="menu" :to="{ name: 'Vehicles' }" >Fordon</router-link>
            </li>
            <li>
                <router-link class="menu" :to="{ name: 'Reports' }" >Rapporter</router-link>
            </li>
            <li>
                <router-link class="menu" :to="{ name: 'Profile' }">Inställningar</router-link>
            </li>
            <li @click.prevent="logout()">
                <a href="#" class="menu">Logga ut</a>
                <!-- <router-link class="menu" :to="{ name: 'Home' }">Logga ut</router-link> -->
            </li>
        </ul>
    </aside>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: 'Sidebar',

    computed: {
        ...mapState(['authenticated'])
    },

    methods: {
        auth() {
            return this.authenticated && this.$route.name !== 'Login' ? true : false;
        },

        logout() {
            this.$store.dispatch('logout').then(() => {
                this.$router.push({ name: 'Login' })
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.sidebar {
    background: #fafafa;
    min-width: 250px;
    max-width: 250px;
    background: #fff;
    border-right: 1px solid #E5E8E8;
    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 150px;

        h5 {
            font-weight: 600;
            color: #566573;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            // padding: 12px 35px;
            list-style: none;
            &:hover {
                background: #F2F4F4;
                a {
                    color: #1F618D;
                }
            }
            a {
                color: #566573;
                text-decoration: none;
                font-weight: 600;
                padding: 12px 32px;
                display: block;
                position: relative;
                letter-spacing: 0.5px;
            }
        }
    }
}
</style>