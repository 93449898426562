<template>
    <div class="main w-100">
        <navbar :title="'Fordon'" />
        <div class="vehicles p-3">
            <div class="card mb-3">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <h4>&nbsp;</h4>
                        </div>
                        <div class="col-md-6 text-right">
                            <button
                                type="button"
                                class="btn btn-success"
                                @click="showModal=true"
                            >
                                <font-awesome-icon icon="plus-circle" /> Lägg till fordon
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="vehicle-lists">
                <table class="table table-hover" v-if="vehicles.length > 0">
                    <tbody>
                        <tr v-for="(vehicle, vehicleKey) in vehicles" :key="vehicleKey">
                            <td class="title">{{ vehicle.vehicle }}</td>
                            <td class="actions">
                                <span class="edit">
                                    <font-awesome-icon icon="edit" />
                                </span>
                                <span class="delete">
                                    <font-awesome-icon icon="trash-alt" />
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="alert alert-primary" v-else>
                    Inga fordon inlagda
                </div>
            </div>
        </div>
        <template>
            <b-modal
                id="modal-1"
                size="lg"
                :title="'Lägg till fordon'"
                :hide-footer="true"
                v-model="showModal"
            >
                <div class="form-group">
                    <input 
                        type="text"
                        class="form-control form-control-lg"
                        placeholder="fordon"
                        v-model="vehicleName"
                        :class="{ 'is-invalid': $v.vehicleName.$error }"
                    >
                    <div class="invalid-feedback" v-if="!$v.vehicleName.required">
                        Du måste anges fordon namn.
                    </div>
                </div>
                <button
                    type="button"
                    class="btn btn-success btn-block btn-lg"
                    @click="saveVehicle()"
                >
                    Spara
                </button>
            </b-modal>
        </template>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import { mapState } from 'vuex'
import { required } from "vuelidate/lib/validators";
export default {
    name: 'Vehicles',
    props: ['title'],

    data() {
        return {
            showModal: false,
            vehicleName: null,
            submitStatus: null
        }
    },

    validations: {
        vehicleName: { required }
    },

    computed: {
        ...mapState(['baseUrl', 'apiKey', 'vehicles'])
    },

    components: {
        Navbar
    },

    mounted() {
        this.$store.dispatch('fetchVehicles');
    },

    methods: {
        saveVehicle() {
            //  api/edit.php?newVehicle med POST-variabel vehicle sparar ett nytt fordon
            this.$v.vehicleName.$touch();
            if (this.$v.vehicleName.$invalid) {
                this.submitStatus = "ERROR";
            } else {
                this.submitStatus = "PENDING";

                var formData = new FormData();
                formData.append('vehicle', this.vehicleName);

                this.$http.post(this.baseUrl+'api/edit.php?type=newVehicle&key='+this.apiKey, formData)
                .then((response) => {
                    if (response.data.status == 'ok') {
                        this.submitStatus = "OK";
                        this.vehicleName = null;
                        this.showModal = false;
                        this.$store.dispatch('fetchVehicles');
                        this.$v.$reset();
                        this.$notify({
                            group: 'alerts',
                            title: 'Meddelande',
                            text: 'Ett fordon tillagd',
                            type: 'success'
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.$notify({
                        group: 'alerts',
                        title: 'Meddelande',
                        text: 'Det gick inte att spara!',
                        type: 'error'
                    });
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.vehicles {
    .vehicle-lists {
        background: #fff;
    }

    .table {
        .title {
            color: #626567;
            font-weight: 600;
            font-size: 15px;
            &:hover {
                color: #A6ACAF;
            }
        }
        .actions {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            span {
                margin-right: 15px;
                cursor: pointer;
                color: #626567;
                font-size: 15px;
            }
            .edit:hover {
                color: #3498DB;
            }
            .delete:hover {
                color: #E74C3C;
            }
        }
    }
}
</style>
