import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import axios from './axios'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faBars, faCaretDown, faCaretUp, faHome, faInfoCircle, faFile, faUsers, faAddressCard, faPlusCircle, faMinusCircle, faPen, faEye, faLightbulb, faChartLine, faUser, faSlidersH, faEllipsisV, faTrashAlt, faAngleRight, faAngleDown, faTimesCircle, faArrowCircleLeft, faArrowCircleRight, faCheckCircle, faFileAlt, faChevronLeft, faClipboard, faShareSquare, faFlag, faDesktop, faExternalLinkAlt, faEdit, faTimes, faFileInvoice, faDatabase, faHouseDamage, faTree, faCircle, faCheck, faSave, faUmbrella, faTags, faMapSigns, faFileSignature, faDonate, faEnvelope, faLifeRing, faFilePdf, faShareAlt, faSync, faChevronUp, faChevronDown, faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import vueSweetalert2 from 'vue-sweetalert2';
import { BootstrapVue } from 'bootstrap-vue';
import Notifications from 'vue-notification'

Vue.prototype.$http = axios

library.add(faUserSecret, faBars, faCaretDown, faCaretUp, faHome, faInfoCircle, faFile, faUsers, faAddressCard, faPlusCircle, faMinusCircle, faPen, faEye, faLightbulb, faChartLine, faUser, faSlidersH, faEllipsisV, faTrashAlt, faAngleRight, faAngleDown, faTimesCircle, faArrowCircleLeft, faFlag, faArrowCircleRight, faCheckCircle, faFileAlt, faChevronLeft, faClipboard, faShareSquare, faDesktop, faExternalLinkAlt, faEdit, faFileInvoice, faDatabase, faHouseDamage, faTimes, faTree, faCircle, faCheck, faSave, faUmbrella, faTags, faMapSigns, faFileSignature, faDonate, faEnvelope, faLifeRing, faFilePdf, faShareAlt, faSync, faChevronUp, faChevronDown, faPrint)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(vueSweetalert2)
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(Notifications)

new Vue({
    router,
    store,
    beforeCreate() {
        this.$store.dispatch('initialiseStore')
    },
    render: h => h(App)
}).$mount('#app')