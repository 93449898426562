
export function isEmailValid(email) {
    var reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/

    if (email == null || email == '') {
        return 'Du måste ange e-post'
    } else if (!reg.test(email)) {
        return 'Ogiltig e-postadress'
    }
    return null
}

export function isPasswordValid(password) {
    if (password == null || password == '') {
        return 'Du måste ange lösenord'
    }
    return null
}

export function isNewPasswordValid(password) {
    if (password == null || password == '') {
        return 'Du måste ange nytt lösenord'
    }
    return null
}

export function isPasswordConfirmed(newPassword, confirmPassword) {
    if (newPassword == null || newPassword == '') {
        return 'Ange nytt lösenord'
    }
    if (newPassword !== confirmPassword) {
        return 'Lösenordet matchade inte'
    }
    return null
}