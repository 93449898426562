<template>
  <div class="personaldetails">
      <b-form v-if="!disabled && person == null">
        <b-form-group label="Namn" class="form-inline">
          <b-form-input
            class="mr-2"
            type="text"
            v-model="firstName"
            placeholder="Förnamn"
            :class="{ 'is-invalid': $v.firstName.$error }"
          ></b-form-input>
          <b-form-input
            type="text"
            v-model="lastName"
            placeholder="Efternamn"
            :class="{ 'is-invalid': $v.lastName.$error }"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Person-ID" class="form-inline">
          <b-form-input
            class="mr-2"
            type="number"
            v-model="pnr"
            placeholder="ÅÅÅÅMMDDNNNN"
            :class="{ 'is-invalid': $v.pnr.$error }"
          ></b-form-input>
          <b-form-input
            type="number"
            v-model="employeeNumber"
            placeholder="Anställningsnummer"
            :class="{ 'is-invalid': $v.employeeNumber.$error }"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Adress" class="form-inline">
          <b-form-input
            class="mb-2"
            type="text"
            v-model="streetAddress"
            placeholder="Gatuadress"
            :class="{ 'is-invalid': $v.streetAddress.$error }"
          ></b-form-input>
          <div class="form-inline">
            <b-form-input
              class="mr-2"
              type="number"
              v-model="postalNumber"
              placeholder="Postnummer"
              :class="{ 'is-invalid': $v.postalNumber.$error }"
            ></b-form-input>
            <b-form-input
              type="text"
              v-model="city"
              placeholder="Ort"
              :class="{ 'is-invalid': $v.city.$error }"
            ></b-form-input>
          </div>
        </b-form-group>
        <b-form-group label="Telefon" class="form-inline">
          <b-form-input
            class="mr-2"
            type="number"
            v-model="mobile"
            placeholder="Mobiltelefon"
            :class="{ 'is-invalid': $v.mobile.$error }"
          ></b-form-input>
          <b-form-input
            type="number"
            v-model="phone"
            placeholder="Privat telefon"
            :class="{ 'is-invalid': $v.phone.$error }"
          ></b-form-input>
        </b-form-group>
        <b-button variant="success" @click="savePerson()">Spara</b-button>
      </b-form>
      <div class="table-responsive" v-if="disabled && person != null">
        <table class="table table-bordered">
          <tr>
            <th>Namn:</th>
            <td>{{ person.firstName }}</td>
          </tr>
          <tr>
            <th>Efternamn:</th>
            <td>{{ person.lastName }}</td>
          </tr>
          <tr>
            <th>Person-ID:</th>
            <td>{{ person.pnr }}</td>
          </tr>
          <tr>
            <th>Anställningsnummer:</th>
            <td>{{ person.employeeNr }}</td>
          </tr>
          <tr>
            <th>Gatuadress:</th>
            <td>{{ person.address }}</td>
          </tr>
          <tr>
            <th>Postnummer:</th>
            <td>{{ person.postcode }}</td>
          </tr>
          <tr>
            <th>Ort:</th>
            <td>{{ person.city }}</td>
          </tr>
          <tr>
            <th>Mobiltelefon:</th>
            <td>{{ person.mobilePhone }}</td>
          </tr>
          <tr>
            <th>Privat telefon:</th>
            <td>{{ person.privatePhone }}</td>
          </tr>
        </table>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required } from "vuelidate/lib/validators";
export default {
  name: "personaldetails",
  props: ["personID"],
  data() {
    return {
      firstName: null,
      lastName: null,
      pnr: null,
      employeeNumber: null,
      streetAddress: null,
      postalNumber: null,
      city: null,
      mobile: null,
      phone: null,
      submitStatus: null,
      person: null
    };
  },

  validations: {
    firstName: { required },
    lastName: { required },
    pnr: { required },
    employeeNumber: { required },
    streetAddress: { required },
    postalNumber: { required },
    city: { required },
    mobile: { required },
    phone: { required },
  },

  computed: {
    ...mapState(['baseUrl', 'apiKey']),

    disabled() {
      return !!this.personID
    }
  },

  mounted() {
    this.fetchPerson();
  },

  methods: {
    fetchPerson() {
      if (this.personID != null) {
        this.$http.get(this.baseUrl+'api/get.php?type=person&personID='+this.personID+'&key='+this.apiKey)
        .then((response) => {
          if (response.data.status == 'ok') {
            this.person = response.data.data
          }
        })
        .catch((error) => {
          console.log(error)
        })
      }
    },

    savePerson() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";
        var formData = new FormData()
        formData.append('firstName', this.firstName)
        formData.append('lastName', this.lastName)
        formData.append('pnr', this.pnr)
        formData.append('employeeNr', this.employeeNumber)
        formData.append('address', this.streetAddress)
        formData.append('postcode', this.postalNumber)
        formData.append('city', this.city)
        formData.append('mobilePhone', this.mobile)
        formData.append('privatePhone', this.phone)

        this.$http.post(this.baseUrl+'api/edit.php?type=addPerson&key='+this.apiKey, formData)
        .then((response) => {
          if (response.data.status == 'ok') {
            this.$router.push({ query: { personID: response.data.data }});
            this.$emit('person', response.data.data);

            this.firstName = null
            this.lastName = null
            this.pnr = null
            this.employeeNumber = null
            this.streetAddress = null
            this.postalNumber = null
            this.city = null
            this.mobile = null
            this.phone = null
            this.$v.$reset()
          }
        })
        .catch((error) => {
          console.log(error)
        })
      }
    }
  }
};
</script>

<style lang="scss">
.personaldetails {
  table th {
    max-width: 40px;
  }
}
</style>