<template>
  <div class="main w-100">
    <navbar :title="'Personal'" />
    <div class="staffs p-3">
      <div class="persons-list">
        <h3>Personuppgifter</h3>
        <table class="table">
          <tbody>
            <tr>
              <td>Namn</td>
              <td>{{ pd.firstName }} {{ pd.lastName }}</td>
            </tr>
            <tr>
              <td>Adress</td>
              <td>{{ pd.address }}</td>
            </tr>
            <tr>
              <td>Postadress</td>
              <td>{{ pd.postcode }} {{ pd.city }}</td>
            </tr>
          </tbody>
        </table>
        <h3>Kompetenser</h3>
        <table class="table" v-if="q.length > 0">
          <thead>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "Share",
  props: ["id"],
  data() {
    return {
      hasMounted: false,
      pd: [],
      q: [],
      edu: [],
      ex: [],
    };
  },

  computed: {
    ...mapState(["baseUrl"]),
  },

  components: {
    Navbar,
  },

  mounted() {
    this.hasMounted = true;
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.$http
        .get(this.baseUrl + "api/share.php?key=" + this.id)
        .then((response) => {
          this.pd = response.data.data.persondata;
          this.q = response.data.data.qualifications;
          this.edu = response.data.data.educations;
          this.ex = response.data.data.expiry;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getMonths(date) {
      return moment(date).diff(moment(), "months", true);
    },
  },
};
</script>
<style lang="scss" scoped>
.staffs {
  .persons-list {
    background: #fff;
  }

  .table {
    tr:hover {
      .title a {
        color: #2e86c1;
      }
    }
    .title {
      a {
        color: #797d7f;
        font-weight: 600;
        &:hover {
          color: #2e86c1;
        }
      }
    }
    .action {
      a {
        color: #2e86c1;
        font-weight: 600;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 1px;
        &:hover {
          color: #797d7f;
        }
      }
    }
  }
}
.status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .red,
  .orange,
  .yellow,
  .green {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .percent {
    font-weight: 600;
    font-size: 14px;
  }
  .green {
    background: #27ae60;
  }
  .yellow {
    background: #f1c40f;
  }
  .orange {
    background: #e67e22;
  }
  .red {
    background: #e74c3c;
  }
}
</style>
