var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main w-100"},[_c('navbar',{attrs:{"title":'Reports'}}),_c('div',{staticClass:"reports p-3"},[_c('b-container',[_c('h3',[_vm._v("Hälsoundersökningar")]),(_vm.healthList != null)?_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Person")]),_c('th',[_vm._v("Datum")]),_c('th',[_vm._v("Dispens utgår")])])]),_c('tbody',_vm._l((_vm.healthList),function(r,index){return _c('tr',{key:index},[_c('td',[_c('router-link',{attrs:{"to":{
                  name: 'Personaldetails',
                  params: { personID: r.staffID },
                }}},[_vm._v(_vm._s(r.firstName)+" "+_vm._s(r.lastName)+" ")])],1),_c('td',[_c('span',{staticClass:"status"},[_c('span',{class:{
                    red: _vm.getMonths(r.expireDate) <= 0,
                    orange:
                      _vm.getMonths(r.expireDate) > 0 &&
                      _vm.getMonths(r.expireDate) <= 1,
                    yellow:
                      _vm.getMonths(r.expireDate) > 1 &&
                      _vm.getMonths(r.expireDate) <= 4,
                    green: _vm.getMonths(r.expireDate) > 4,
                  }}),_vm._v(" "+_vm._s(r.expireDate))])]),_c('td',[_vm._v(_vm._s(r.exemptExpire))])])}),0)]):_vm._e(),_c('h3',[_vm._v("Utbildningar")]),(_vm.educationList != null)?_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Person")]),_c('th',[_vm._v("Utbildningar")])])]),_c('tbody',_vm._l((_vm.educationList),function(r,index){return _c('tr',{key:index},[_c('td',[_c('router-link',{attrs:{"to":{
                  name: 'Personaldetails',
                  params: { personID: r.staffID },
                }}},[_vm._v(_vm._s(r.firstName)+" "+_vm._s(r.lastName)+" ")])],1),_c('td',[_c('ul',_vm._l((r.data),function(d,index2){return _c('li',{key:index2},[_c('span',{staticClass:"status"},[_c('span',{class:{
                    red: _vm.getMonths(d.expireDate) <= 0,
                    orange:
                      _vm.getMonths(d.expireDate) > 0 &&
                      _vm.getMonths(d.expireDate) <= 1,
                    yellow:
                      _vm.getMonths(d.expireDate) > 1 &&
                      _vm.getMonths(d.expireDate) <= 4,
                    green: _vm.getMonths(d.expireDate) > 4,
                  }}),_vm._v(" "+_vm._s(d.expireDate)+" "+_vm._s(d.education))])])}),0)])])}),0)]):_vm._e(),_c('h3',[_vm._v("Kompetenser")]),(_vm.qualificationList != null)?_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Person")]),_c('th',[_vm._v("Utbildningar")])])]),_c('tbody',_vm._l((_vm.qualificationList),function(r,index){return _c('tr',{key:index},[_c('td',[_c('router-link',{attrs:{"to":{
                  name: 'Personaldetails',
                  params: { personID: r.staffID },
                }}},[_vm._v(_vm._s(r.firstName)+" "+_vm._s(r.lastName)+" ")])],1),_c('td',[_c('ul',_vm._l((r.data),function(d,index2){return _c('li',{key:index2},[_vm._v(" "+_vm._s(d.qualification))])}),0)])])}),0)]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }