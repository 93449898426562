<template>
  <div class="main w-100">
    <navbar :title="'Kompetenser'" />
    <div class="qualifications p-3">
      <div class="card mb-3">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <!-- <h4>Utbildningar</h4> -->
              <h4>&nbsp;</h4>
            </div>
            <div class="col-md-6 text-right">
              <button
                type="button"
                class="btn btn-success"
                @click="showModal = true"
              >
                <font-awesome-icon icon="plus-circle" /> Lägg till kompetens
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="qual-lists" :key="componentKey">
        <template v-if="qualifications.length > 0">
          <qualification
            v-for="(qualification, qualificationKey) in qualifications"
            :key="qualificationKey"
            :qualification="qualification"
            @expanded="collapsed"
            @open="openModal"
            :requirements="filterRequirements(qualification)"
            class="qf"
          />
        </template>
        <div class="alert alert-primary" v-else>Ingen kompetens hittades</div>
      </div>
    </div>
    <template>
      <b-modal
        id="modal-1"
        size="lg"
        :title="'Lägg till kompetens'"
        :hide-footer="true"
        v-model="showModal"
        @hide="reset()"
      >
        <div class="form-group">
          <input
            type="text"
            class="form-control form-control-lg"
            placeholder="Namn på kompetensen"
            v-model="qualificationName"
            :class="{ 'is-invalid': $v.qualificationName.$error }"
          />
          <div class="invalid-feedback" v-if="!$v.qualificationName.required">
            Kompetens måste fyllas i.
          </div>
        </div>
        <button
          type="button"
          class="btn btn-success btn-block btn-lg"
          @click="saveQualification()"
        >
          Spara
        </button>
      </b-modal>
    </template>
    <template>
      <b-modal
        id="modal-1"
        size="lg"
        :title="'Lägg till kompetenskrav'"
        :hide-footer="true"
        v-model="showModalQualification"
        @hide="reset()"
      >
        <div class="form-group">
          <select class="form-control" v-model="requirement.education">
            <option :value="null">Välj utbildning</option>
            <option
              :value="education"
              v-for="(education, eduKey) in educations"
              :key="eduKey"
            >
              {{ education.education }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control form-control-lg"
            placeholder="Antal månader"
            v-model="requirement.months"
          />
        </div>
        <button
          type="button"
          class="btn btn-success btn-block btn-lg"
          @click="saveQualificationRequirement()"
        >
          Spara
        </button>
      </b-modal>
    </template>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import Qualification from "@/components/Qualification";
export default {
  name: "Qualifications",
  props: ["title"],
  data() {
    return {
      showModal: false,
      showModalQualification: false,
      qualificationName: null,
      submitStatus: null,
      qualificationRequirements: [],
      qualification: null,
      requirement: {
        education: null,
        months: null,
      },
      componentKey: 0,
    };
  },

  validations: {
    qualificationName: { required },
  },

  computed: {
    ...mapState(["baseUrl", "apiKey", "qualifications", "educations"]),
  },

  components: {
    Navbar,
    Qualification,
  },

  mounted() {
    this.$store.dispatch("fetchQualifications");
    this.fetchQualificationRequirements();
    this.$store.dispatch("fetchEducations");
  },

  methods: {
    saveQualification() {
      this.$v.$touch();
      console.log("Savequalification...");
      if (this.$v.$invalid) {
        console.log("Error");
        this.submitStatus = "ERROR";
      } else {
        console.log("Saving");
        this.submitStatus = "PENDING";
        var formData = new FormData();
        formData.append("qualification", this.qualificationName);
        this.$http
          .post(
            this.baseUrl +
              "api/edit.php?type=addQualification&key=" +
              this.apiKey,
            formData
          )
          .then((response) => {
            if (response.data.status == "ok") {
              this.qualificationName = null;
              this.showModal = false;
              this.$store.dispatch("fetchQualifications");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    fetchQualificationRequirements() {
      this.$http
        .get(
          this.baseUrl +
            "api/get.php?type=qualificationRequirements&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.qualificationRequirements = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    saveQualificationRequirement() {
      this.submitStatus = "PENDING";
      var formData = new FormData();
      formData.append("educationID", this.requirement.education.educationID);
      formData.append("qualificationID", this.qualification.qualificationID);
      formData.append("months", this.requirement.months);
      this.$http
        .post(
          this.baseUrl +
            "api/edit.php?type=addQualificationRequirement&key=" +
            this.apiKey,
          formData
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.requirement = {
              education: null,
              months: null,
            };
            this.fetchQualificationRequirements();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    collapsed(qualification) {
      this.qualification = qualification;
      this.componentKey += 1;
    },

    openModal(qualification) {
      this.qualification = qualification;
      this.showModalQualification = true;
    },

    filterRequirements(qualification) {
      return this.qualificationRequirements.filter(
        (qr) => qr.qualificationID == qualification.qualificationID
      );
    },

    reset() {
      this.$v.$reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.qualifications {
  .qual-lists {
    background: #fff;
  }
  .qf {
    border-radius: 0;
  }
  .qf:not(:last-child) {
    border-bottom: 0;
  }
}
</style>