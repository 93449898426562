<template>
    <div class="card qualification">
        <div class="card-body">
            <p class="title" @click="expand(qualification)">{{ qualification.qualification }}</p>
            <p class="actions">
                <span class="edit">
                    <font-awesome-icon icon="edit" />
                </span>
                <span class="delete">
                    <font-awesome-icon icon="trash-alt" />
                </span>
                <span class="expand" >
                    <font-awesome-icon icon="chevron-up" v-if="qualification.selected" />
                    <font-awesome-icon icon="chevron-down" v-if="!qualification.selected" />
                </span>
            </p>
        </div>
        <div class="requirments" v-if="qualification.selected">
            <ul v-if="requirements.length > 0">
                <li v-for="(requirement, rKey) in requirements" :key="rKey">
                    {{ requirement.education }} <strong>({{ requirement.months }} {{ (requirement.months > 0) ? 'Månader' : 'Månad'}})</strong>
                </li>
            </ul>
            <div class="alert alert-primary" v-if="qualification == 0">
                Inga kompetenskrav hittades
            </div>
             <button
                type="button"
                class="btn btn-secondary mr-2"
                @click="modal(qualification)"
            >
                <font-awesome-icon icon="plus-circle" /> Lägg till kompetenskrav
            </button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'qualification',
    props: ['qualification', 'requirements'],

    computed: {
        ...mapState(['qualifications'])
    },

    methods: {
        expand(qualification) {
            this.qualifications.map((q) => {
                if (q == qualification) {
                    q.selected = !q.selected
                } else {
                    q.selected = false
                }
            })
            this.$emit('expanded', qualification)
        },

        modal(qualification) {
            this.$emit('open', qualification)
        }
    }
}
</script>

<style lang="scss" scoped>
.qualification {
    .card-body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &:hover {
            background: #f1f1f1;
        }
        p {
            margin: 0;
        }
        .id {
            flex: 1;
            font-weight: 600;
        }
        .title {
            flex: 5;
            font-weight: 600;
            color: #626567;
            font-size: 15px;
            cursor: pointer;
            &:hover {
                color: #A6ACAF;
            }
        }
        .actions {
            flex: 1;
            text-align: right;
            span {
                margin-right: 15px;
                cursor: pointer;
                color: #626567;
                font-size: 15px;
            }
            .edit:hover {
                color: #3498DB;
            }
            .delete:hover {
                color: #E74C3C;
            }
        }
    }
    .requirments {
        padding: 15px 20px;
        border-top: 1px solid #ddd;
    }
}
</style>